/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BottomRightDialog from 'components/BottomRightDialog';
import { toolArea, toolLine, toolMarker } from 'assets';
import Section from './Section';
import styles from './styles.module.scss';

export const linkStyles = makeStyles({
  button: {
    fontWeight: 700,
    fontSize: '1rem',
    marginRight: 10,
  },
});

const inputStyles = makeStyles({
  root: {
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: 'white',
    },
    padding: '0',
  },
  input: {
    padding: '8px 10px',
    backgroundColor: 'white',
    fontSize: '16px',
  },
});
const textStyles = makeStyles({
  root: {
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  input: {
    backgroundColor: 'white',
    padding: '8px 10px',
    fontSize: '24px',
    fontWeight: '700',
  },
});

const CreateDialog = ({ title, close, children }) => {
  let icon = toolMarker;
  if (title == 'Create Line') {
    icon = toolLine;
  } else if (title == 'Create New Shape') {
    icon = toolArea;
  }
  return (
    <BottomRightDialog title={title} close={close} icon={icon} backgroundColor="#fff">
      {children}
    </BottomRightDialog>
  );
};

CreateDialog.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func,
  children: PropTypes.node,
};

CreateDialog.Section = Section;

CreateDialog.SubmitSection = ({ buttons, loading }) => {
  const linkClasses = linkStyles();

  return (
    <div className={styles['submit-container']} style={{ display: 'flex', justifyContent: 'space-between' }}>
      {buttons && buttons.map(({ label, onClick, error }) => {
        const buttonClass = label === 'Cancel' ? '' : styles.button_primary;
        return (
          // <Link classes={linkClasses} component="button" underline="none" onClick={onClick} color={error ? 'error' : 'primary'} key={label} disabled={loading}>{label}</Link>
          <button key={label} className={`${styles.button} ${buttonClass}`} type="button" onClick={onClick}>{label}</button>
        );
      })}
    </div>
  );
};

CreateDialog.SubmitSection.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    error: PropTypes.bool,
  })),
  loading: PropTypes.bool,
};

CreateDialog.TextInput = ({
  onChange, value, name,
}) => {
  return (
    <TextField
      inputProps={{ className: styles.textinput }}
      name={name}
      onChange={onChange}
      value={value}
      type="text"
      variant="outlined"
      fullWidth
    />
  );
};

CreateDialog.TextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};

CreateDialog.InputField = ({
  onChange, value, name, label,
}) => {
  const inputClasses = inputStyles();

  return (
    <TextField
      InputProps={{ classes: inputClasses }}
      inputProps={{ classes: inputClasses.input }}
      name={name}
      onChange={onChange}
      value={value}
      type="text"
      variant="filled"
      label={label}
      fullWidth
    />
  );
};

CreateDialog.InputField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

CreateDialog.MultiLineField = ({
  onChange, value, name, label,
}) => {
  return (
    <TextField
      inputProps={{ className: styles.textarea }}
      name={name}
      multiline
      type="text"
      variant="outlined"
      minRows={5}
      maxRows={6}
      fullWidth
      value={value}
      onChange={onChange}
    />
  );
};

CreateDialog.MultiLineField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

CreateDialog.Selector = ({
  items, renderItem,
}) => {
  return (
    <div className={styles.selector}>
      {items.map((item) => {
        return renderItem(item);
      })}
    </div>
  );
};

CreateDialog.Selector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
};

CreateDialog.Row = ({ children }) => {
  return (
    <div className={styles.row}>{children}</div>
  );
};

export default CreateDialog;
