import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import ThreeButtons from 'components/ThreeButtons';
import { updateStreamGeography } from 'actions';
import {
  distanceIcon, elevationIcon, closeIcon,
} from 'assets';
import CloseIcon from '@material-ui/icons/Close';
import { store } from '../../index';
import styles from './styles.module.scss';

const Popup3 = ({
  title, subtitle, type, distance, elevation, leftButton, middleButton, rightButton, iconURL, fourthButton, dispatch, color,
}) => {
  const { tapMarker } = store.getState().map.marker;
  const onClose = async () => {
    const { map } = store.getState().map;
    const { stream } = store.getState().map;
    map.fire('closeAllPopups');
    if (tapMarker) {
      tapMarker.remove();
    }
    if (stream) {
      stream.isDetail = false;
      dispatch(updateStreamGeography({ isDetail: false }));
    }
    document.getElementsByClassName('mapboxgl-popup')[0].remove();
  };

  return (
    <>
      {dispatch && (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.close}
              role="button"
              onClick={onClose}
              tabIndex={0}
            >
              <CloseIcon />
              {/* <Image alt="Close" src={closeIcon} width="16" /> */}
            </div>
            <div className={styles.text}>
              <div className={styles.type}><span className={styles.circle} style={{ backgroundColor: color }} /><h6>{type}</h6></div>
              <h5>{title}</h5>
              <h6>{subtitle}</h6>
            </div>
          </div>
          <div className={styles.distances}>
            <div className={styles.distanceInner}>
              <Image alt="Distance" src={distanceIcon} width="20" />
              <p>{distance}</p>
            </div>
            <div className={styles.distanceInner}>
              <Image alt="Elevation" src={elevationIcon} width="20" />
              <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft elev.</p>
            </div>
          </div>

          <ThreeButtons leftButton={leftButton} middleButton={middleButton} rightButton={rightButton} fourthButton={fourthButton} />
        </div>
      )}
      {!dispatch && (
        <div className={styles.popup}>
          <div className={styles.close}
            role="button"
            onClick={onClose}
            tabIndex={0}
          >
            <CloseIcon />
            {/* <Image alt="Close" src={closeIcon} width="16" /> */}
          </div>
          <div className={styles.content}>
            {iconURL && <Image alt={title} src={iconURL} width="20%" objectFit="contain" />}

            <div className={styles.text}>
              <h5>{title}</h5>
              <h6>{subtitle}</h6>
              {type && <p>{type}</p>}
            </div>
          </div>

          <div className={styles.distances}>
            <div className={styles.distanceInner}>
              <Image alt="Distance" src={distanceIcon} width="20" />
              <p>{distance}</p>
            </div>
            <div className={styles.distanceInner}>
              <Image alt="Elevation" src={elevationIcon} width="20" />
              <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft elev.</p>
            </div>
          </div>

          <ThreeButtons leftButton={leftButton} middleButton={middleButton} rightButton={rightButton} fourthButton={fourthButton} />
        </div>
      )}
    </>
  );
};

Popup3.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  distance: PropTypes.string,
  elevation: PropTypes.string,
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  }),
  middleButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  iconURL: PropTypes.string,
};

export default Popup3;
