import React from 'react';
import {
  markerIcon, polyIcon, lineIcon,
} from 'assets';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'components/Image';
import { setActiveTools, setIsUpgradeModalShown } from 'actions';
import useIsPro from 'utils/useIsPro';
import useWindowSize from 'utils/useWindowSize';
import styles from './styles.module.scss';
import zoomStyles from '../ZoomControls/styles.module.scss';
import ZoomButton from '../ZoomControls/ZoomButton';

const BoxLabel = () => {
  return (
    <div className={styles['box-label']}>
      <span>Map</span>
      <span>Tools</span>
    </div>
  );
};

const MapTools = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const activeTools = useSelector((state) => state.map.activeTools);
  const { width } = useWindowSize();

  const markerClick = () => {
    dispatch(setActiveTools({ marker: !activeTools.marker, line: false, area: false }));
  };

  const lineClick = () => {
    if (isPro) {
      dispatch(setActiveTools({ marker: false, area: false, line: !activeTools.line }));
    } else {
      dispatch(setIsUpgradeModalShown(true));
    }
  };

  const areaClick = () => {
    if (isPro) {
      dispatch(setActiveTools({ marker: false, line: false, area: !activeTools.area }));
    } else {
      dispatch(setIsUpgradeModalShown(true));
    }
  };

  const instructionsText = () => {
    if (activeTools.marker) return 'Tap a spot on the map to create a marker.';
    else if (activeTools.line) return 'Tap spots on the map to create a line.';
    else if (activeTools.area) return 'Tap spots on the map to create a polygon.';
    else return '';
  };

  const isActive = activeTools.marker || activeTools.line || activeTools.area;

  const MarkerIcon = () => (<Image src={markerIcon} height={30} maxHeight={30} width={30} objectFit="cover" />);
  const LineIcon = () => (<Image src={lineIcon} height={30} maxHeight={30} width={30} objectFit="cover" />);
  const PolyIcon = () => (<Image src={polyIcon} height={30} maxHeight={30} width={30} objectFit="cover" />);

  return (
    <div className={zoomStyles.group}>
      <ZoomButton icon={MarkerIcon} name="Create Marker" onClick={markerClick} active={activeTools.marker} />
      <div className={zoomStyles.divider} />
      <ZoomButton icon={LineIcon} name="Create Line" onClick={lineClick} active={activeTools.line} />
      <div className={zoomStyles.divider} />
      <ZoomButton icon={PolyIcon} name="Create Area" onClick={areaClick} active={activeTools.area} />
    </div>
  );
};

export default MapTools;
