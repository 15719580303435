/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTempMarker, createMarker, resetCurrentMarker, setMarkerType, setDelayedPanelShown, setTRPOIFeature, setEditingMarkerId,
} from 'actions';
import {
  distanceIcon, elevationIcon,
} from 'assets';
import Image from 'components/Image';
import { lineColors, mapMarkerTypes } from 'lib/constants';
import * as mapboxService from 'services/mapbox';
import CreateDialog from 'components/CreateDialog';
import useUpload from 'utils/useUpload';
import ColorButton from 'components/CreateLine/ColorButton';
import { PhotoDisplay, AddPhotoButton } from 'components/Photos';
import useIsPro from 'utils/useIsPro';
import IconButton from './IconButton';
import { getElevationPoint } from '../../services/elevation';
import Select from 'react-select';
import styles from './styles.module.scss';

const CreateMarker = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const [currentColor, setCurrentColor] = useState(lineColors[0]);
  const { tempMarker: marker, currentMarkerType: markerTypeIndex } = useSelector((state) => state.map.marker);
  const map = useSelector((state) => state.map.map);
  const { trPOIFeature } = useSelector((state) => state.map.trPOIFeature);
  const [address, setAddress] = useState('');
  const [distance, setDistance] = useState();
  const [elevation, setElevation] = useState(0);

  const {
    images, handleChange, setImages, loading, handleUpload,
  } = useUpload();

  const [markerName, setMarkerName] = useState('');
  const [notes, setNotes] = useState('');

  const close = () => {
    dispatch(resetCurrentMarker());
    setMarkerName('');
    setNotes('');
    setImages([]);
    if (trPOIFeature) {
      trPOIFeature.isCreate = false;
      dispatch(setTRPOIFeature(trPOIFeature));
    }
  };

  const onDeleteImage = (imageObject) => {
    setImages((prev) => prev.filter((v) => v !== imageObject));
  };

  const onChangeType = (ev) => {
    if (marker) {
      marker.remove();
    }
    const markerType = mapMarkerTypes[ev.value];
    const icon = React.cloneElement(markerType.icon, { colorIndex: currentColor?.index.toString() });

    const newMarker = mapboxService.createIconMarker(marker.getLngLat(), map, icon);
    dispatch(setTempMarker(newMarker));
    dispatch(setMarkerType(ev.value));
  };

  const onChangeColor = (ev) => {
    const curClr = lineColors.find((m) => m.index === ev.value);
    setCurrentColor(curClr);
    
    if (marker) {
      marker.remove();
    }

    const markerType = mapMarkerTypes[markerTypeIndex];
    const icon = React.cloneElement(markerType.icon, { colorIndex: ev.value });
    const newMarker = mapboxService.createIconMarker(marker.getLngLat(), map, icon);

    dispatch(setTempMarker(newMarker));
    dispatch(setMarkerType(markerType.index));
  }

  const onSubmit = () => {
    const markerType = mapMarkerTypes[markerTypeIndex];
    dispatch(createMarker({
      lngLat: marker.getLngLat(), name: markerName, markerType, notes: notes, pointColorIndex: currentColor.index.toString(),
    }, null, (id) => {
      handleUpload(id, 'marker');
      close();
      dispatch(setTRPOIFeature(null));
      dispatch(setEditingMarkerId(id));
    }));
  };

  useEffect(async () => {
    if (marker) {
      setTimeout(() => { dispatch(setDelayedPanelShown(true)); }, 600);
    } else {
      dispatch(setDelayedPanelShown(false));
    }
    console.log(marker)

    setMarkerName('No title');
    if(trPOIFeature) {
      if(trPOIFeature.properties.GeoName) {
        setMarkerName(trPOIFeature.properties.GeoName);
      }
      if(trPOIFeature.properties.desc) {
        setNotes(trPOIFeature.properties.desc);
      }

      if (trPOIFeature.properties.SecondaryLabel) {
        if (trPOIFeature.properties.SecondaryLabel.includes(',')) {
          setAddress(trPOIFeature.properties.SecondaryLabel.replace(',', ', '));
        } else {
          setAddress(trPOIFeature.properties.SecondaryLabel);
        }
      } else {
        setAddress('');
      }

      if(trPOIFeature.properties.elevation_ft) {
        setElevation(trPOIFeature.properties.elevation_ft);
      } else {
        const elevationInfo = await getElevationPoint({ lat: trPOIFeature.geometry.coordinates[1], lng: trPOIFeature.geometry.coordinates[0] });
        setElevation(elevationInfo);
        setDistance(mapboxService.distanceFormat(geometry.coordinates, false));
      }
    } else if (marker && marker._lngLat) {
      const elevationInfo = await getElevationPoint({ lat: marker._lngLat.lat, lng: marker._lngLat.lng });
      setElevation(elevationInfo);
      setDistance(mapboxService.distanceFormat([marker._lngLat.lng, marker._lngLat.lat], false));
    }

  }, [marker]);

  if (!marker) return null;

  // color dropdown
  var colors = [];
  lineColors.forEach((color) => {
    colors.push({ value: color.index, label: color.color });
  });
  const customOptionColor = (props) => {
    const { innerRef, innerProps, data } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ padding: '4px 8px' }}>
        <div className={styles.selectColor} style={{ background: data.label }}></div>
      </div>
    );
  };
  const customSingleValueColor = ({ data }) => (
    <div>
      <div className={styles.selectColor} style={{ background: data.label }}></div>
    </div>
  );

  // type dropdown
  var types = [];
  mapMarkerTypes.forEach((type) => {
    types.push({ value: type.index, label: type.shortName, img: type.img });
  });
  const customOptionType = (props) => {
    const { innerRef, innerProps, data } = props;
    return (
      <div ref={innerRef} {...innerProps} className={styles.selectType} style={{ padding: '4px 8px' }}>
        <img src={data.img} />
        <div>{data.label}</div>
      </div>
    );
  };
  const customSingleValueType = ({ data }) => (
    <div className={styles.selectType}>
      <img src={data.img} />
      <div>{data.label}</div>
    </div>
  );

  return (
    <CreateDialog close={close} title="Create New Marker">
      
      <div className={styles.flex} style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Select
          className={styles.selector}
          isSearchable={false}
          defaultValue={colors[0]}
          options={colors}
          components={{ Option: customOptionColor, SingleValue: customSingleValueColor }}
          onChange={onChangeColor}
        />
        <Select
          className={styles.selector}
          isSearchable={false}
          defaultValue={types[0]}
          options={types}
          components={{ Option: customOptionType, SingleValue: customSingleValueType }}
          onChange={onChangeType}
        />
      </div>

      <div className={styles.scontent}>
        <CreateDialog.TextInput
          name="name"
          onChange={(e) => { (e.target.value=='') ? setMarkerName('No title') : setMarkerName(e.target.value); }}
          value={markerName}
        />

        <div className={styles.address}>
          {address}
        </div>
        <div className={styles.distances}>
          <div className={styles.distanceInner}>
            <Image alt="Distance" src={distanceIcon} width="16" />
            <p>{distance}</p>
          </div>
          <div className={styles.distanceInner}>
            <Image alt="Elevation" src={elevationIcon} width="16" />
            <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft. elevation</p>
          </div>
        </div>

        <CreateDialog.Section title="Notes">
          <CreateDialog.MultiLineField
            name="notes"
            label="Notes"
            value={notes}
            onChange={(e) => { setNotes(e.target.value); }}
          />
        </CreateDialog.Section>

        <div className={styles.divider} style={{ marginBottom: '0px' }} />
        {isPro && (
          <CreateDialog.Section title="Photos" headerIcon={<AddPhotoButton id="create-marker" onChange={handleChange} />}>
            <PhotoDisplay images={images} onDelete={onDeleteImage} />
            <div className={styles.divider} style={{ marginBottom: '0px' }} />
          </CreateDialog.Section>
        )}
      </div>

      <CreateDialog.SubmitSection buttons={[{ label: 'Save', onClick: onSubmit }, { label: 'Cancel', onClick: close }]} disabled={loading} />
    </CreateDialog>
  );
};

export default CreateMarker;
