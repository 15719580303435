import React, { useState, useEffect } from 'react';
import {
  InputLabel, Select, MenuItem, FormControl, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'components/Image';
import { snapRegions } from 'lib/constants';
import * as mapboxService from 'services/mapbox';
import * as firebaseService from 'services/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { updateRegion } from 'actions';
import { zoomTo, chevronDown } from 'assets';
import styles from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '6px',
  },
  select: {
    width: window.innerWidth > 675 ? 140 : 90,
    paddingRight: '0px!important',
    fontSize: '16px',
    background: 'white!important',
    fontWeight: '500',
    fontFamily: 'Poppins',
  },
  paper: {
    borderRadius: 10,
    marginTop: 52,
    marginLeft: -14,
    width: 174,
  },

}));

export const Selector = ({
  value, onChange, children, classes,
}) => {
  const otherClasses = useStyles();

  return (
    <FormControl className={otherClasses.formControl}>
      {/* <InputLabel id="label">Region</InputLabel> */}
      <Select
        labelId="label"
        id="select"
        value={value}
        onChange={onChange}
        classes={{ root: otherClasses.select, ...classes }}
        disableUnderline
        MenuProps={{ classes: { paper: otherClasses.paper } }}
        IconComponent={(props) => (
          <Image src={chevronDown} {...props} className={`material-icons ${props.className}`} style={{ marginTop: 4 }} />
        )}
      >
        {snapRegions.map(({ title }) => {
          return (
            <MenuItem value={title} key={title} className={styles.mitem}>{title}</MenuItem>
          );
        })}
      </Select>
      {children}
    </FormControl>
  );
};

const Snapper = () => {
  const [value, setValue] = useState('');
  const { map } = useSelector((state) => state.map);
  const dispatch = useDispatch();
  const { region: userRegion } = useSelector((state) => state.user);

  const onSnap = () => {
    if (value) {
      const region = snapRegions.find((r) => r.title === value);
      if (region) {
        mapboxService.snapToRegion(map, region);
        firebaseService.updateSnapRegion(region.gid);
        dispatch(updateRegion(region.gid));
      }
    }
  };

  useEffect(() => {
    if (userRegion) {
      const regionObject = snapRegions.find((region) => region.gid == userRegion);
      if (regionObject) {
        mapboxService.snapToRegion(map, regionObject);
        setValue(regionObject.title);
      }
    }
  }, [userRegion]);

  return (
    <div className={styles.snapper}>
      <Selector value={value} onChange={(e) => { setValue(e.target.value); }}>
        <div>
          <IconButton className={styles.goButton} aria-label="snap" onClick={onSnap}>
            {/* <div className={styles['go-text']}>Go</div> */}
            <Image src={zoomTo} />
          </IconButton>
        </div>
      </Selector>
    </div>
  );
};

export default Snapper;
