import React, { useCallback, useState, useEffect } from 'react';
import ContentDialog from 'components/ContentDialog';
import {
  lineColors, lineWidths, lineTypes, directionsURL,
} from 'lib/constants';
import { createArea, setDelayedPanelShown, deleteArea } from 'actions';
import { areaIcon } from 'assets';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import ExploreIcon from '@material-ui/icons/Explore';
import ShareIcon from '@material-ui/icons/Share';
import * as mapboxService from 'services/mapbox';
import * as firebaseService from 'services/firebase';
import { PhotoDisplay, AddPhotoButton } from 'components/Photos';
import useUpload from 'utils/useUpload';
import useIsPro from 'utils/useIsPro';

const EditAreaDialog = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const draw = useSelector((state) => state.map.draw);
  const map = useSelector((state) => state.map.map);
  const { areaId } = useSelector((state) => state.map.area);
  const { areas, areaIds } = useSelector((state) => state.user);

  const [areaName, setAreaName] = useState('');
  const [currentColor, setCurrentColor] = useState(lineColors[0]);
  const [currentWidth, setCurrentWidth] = useState(lineWidths[1]);
  const [currentType, setCurrentType] = useState(lineTypes[0]);
  const [notes, setNotes] = useState('');
  const [opacity, setOpacity] = useState(0.5);
  const [outlineOpacity, setOutlineOpacity] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [images, setImages] = useState([]);

  const {
    images: tempImages, handleChange, setImages: setTempImages, loading, handleUpload,
  } = useUpload();

  const reset = useCallback(() => {
    if (areaId) {
      const areaInfo = areas[areaIds[areaId]];
      if (areaInfo) {
        const {
          title, note, fillColorIndex, lineWidthIndex, lineTypeIndex, fillOpacity, outlineOpacity: initialOutlineOpacity,
        } = areaInfo.properties;
        setAreaName(title);
        setNotes(note);
        setCurrentColor(fillColorIndex ? lineColors[fillColorIndex] : lineColors[0]);
        setCurrentWidth(lineWidthIndex ? lineWidths[lineWidthIndex] : lineWidths[1]);
        setCurrentType(lineTypeIndex ? lineTypes[lineTypeIndex] : lineTypes[0]);
        setOpacity(fillOpacity || 0.5);
        setOutlineOpacity(initialOutlineOpacity || 1);
        setTempImages([]);
      }
    }
  }, [areaId, areaIds, areas]);

  const onCancel = () => {
    reset();
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onSubmit = () => {
    const area = draw.get(areaId);
    dispatch(createArea({
      colorIndex: currentColor.index, title: areaName, note: notes, geometry: area.geometry, widthIndex: currentWidth.index, typeIndex: currentType.index, fillOpacity: opacity, outlineOpacity,
    }, areaIds[areaId]));

    if (tempImages) {
      handleUpload(areaIds[areaId], 'area');
    } else {
      setIsEditing(false);
    }
  };

  const onDelete = () => {
    mapboxService.clearAreaBorder(map, areas[areaIds[areaId]]);
    dispatch(deleteArea(areaIds[areaId]));
  };

  const onDeleteImage = (imageObject) => {
    const { id } = imageObject;

    if (id) {
      firebaseService.deleteImage(areaIds[areaId], id, 'area');
      setImages((prev) => prev.filter((v) => v.id !== id));
    } else {
      setTempImages((prev) => prev.filter((v) => v !== imageObject));
    }
  };

  useEffect(() => {
    if (!areaId) {
      dispatch(setDelayedPanelShown(false));
      setImages([]);
    } else {
      setTimeout(() => { dispatch(setDelayedPanelShown(true)); }, 600);

      if (draw.get(areaId) && areaIds[areaId] && areas[areaIds[areaId]]) {
        const { properties } = areas[areaIds[areaId]];
        firebaseService.getImageURLsForAnnotation(areaIds[areaId], properties?.imageURLs || [], 'area').then((results) => setImages(results));
      }
    }
  }, [areaId, areas]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (!loading) {
      setIsEditing(false);
    }
  }, [loading]);

  useEffect(() => {
    if (areaId && draw.get(areaId)) {
      draw.setFeatureProperty(areaId, 'color', currentColor.color);
      draw.setFeatureProperty(areaId, 'opacity', opacity);

      const area = draw.get(areaId);
      draw.delete(areaId);
      draw.add(area);
      draw.changeMode('simple_select', { featureIds: [areaId] }); // Ensures selection after the first click.
      mapboxService.clearAreaBorder(map, area);
      mapboxService.addAreaBorder(area, map, {
        color: currentColor.color, width: currentWidth.width, dashArray: currentType.dashArray, opacity: outlineOpacity,
      });
    }
  }, [currentColor, currentWidth, currentType, areaId, draw]);

  if (!areaId) return null;

  const properties = areas[areaIds[areaId]]?.properties;
  const geometry = areas[areaIds[areaId]]?.geometry;
  const coordinates = geometry?.coordinates ? geometry?.coordinates[0][0] : [0, 0];
  const hasImages = !!properties?.imageURLs;

  return (
    <ContentDialog>
      <ContentDialog.Header icon={areaIcon} title={areaName} subtitle="Area" isEditing={isEditing} onChange={(e) => { setAreaName(e.target.value); }} />
      <ContentDialog.Buttons
        buttons={isEditing
          ? [
            { label: 'Cancel', onClick: onCancel },
            { label: 'Save', onClick: onSubmit },
          ]
          : [
            { label: 'Edit', iconElement: EditIcon, onClick: onEdit },
            { label: 'Directions', iconElement: ExploreIcon, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinates[1]}, ${coordinates[0]}`)}`); } },
            // { label: 'Share', iconElement: ShareIcon },
          ]}
        style={{ justifyContent: isEditing ? 'flex-start' : 'space-between' }}
        disabled={loading}
      />
      <ContentDialog.Heading>Notes</ContentDialog.Heading>

      {isEditing
        ? <ContentDialog.MultiLineField onChange={(e) => { setNotes(e.target.value); }} value={notes} name="notes" label="Notes" />
        : <ContentDialog.Text>{notes || 'No notes yet. Tap \'edit\' to create one!'}</ContentDialog.Text>}

      {(hasImages || isEditing) && isPro && (
        <>
          <ContentDialog.Heading>
            <div>Photos</div>
            {isEditing && <AddPhotoButton id="area-upload" onChange={handleChange} />}
          </ContentDialog.Heading>
          <PhotoDisplay images={[...images, ...tempImages]} onDelete={onDeleteImage} />
        </>
      )}

      <ContentDialog.SubmitSection buttons={[{ label: 'Delete', error: true, onClick: onDelete }]} />
    </ContentDialog>
  );
};

export default EditAreaDialog;
