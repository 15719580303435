import React, { useEffect, useState } from 'react';
import Image from 'components/Image';
import { gage, thermostat } from 'assets';
import {
  HeightOutlined, LanguageOutlined, WavesOutlined, TrendingUpOutlined,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import { setCurrentGage, setIsGageChartShown, setStreamFlowsForCurrentStream } from 'actions';
import {
  flyToPoint, hideHUC, showHUC, createMarkerWithoutIcon,
} from 'services/mapbox';
import UpgradeBox from 'components/UpgradeBox';
import useIsPro from 'utils/useIsPro';
import styles from './styles.module.scss';

const proTitle = 'Stream Conditions';
const proDescription = 'Unlock real-time stream conditions for the area, including real-time flows, height, and temperature data for any USGS stages within the watershed.';

function StreamFlowsTab() {
  const isPro = useIsPro();
  const dispatch = useDispatch();
  const { map, currentHUC } = useSelector((state) => state.map);
  const streamGageList = useSelector((state) => state.map.streamGageData);
  const [isWatershedVisible, setIsWatershedVisible] = useState(false);
  const [curMarker, setCurMarker] = useState(null);

  useEffect(() => {
    dispatch(setStreamFlowsForCurrentStream(map, currentHUC));
  }, [currentHUC]);


  useEffect(() => {
    const handleScroll = () => {
      const header1 = document.querySelector('.header:nth-of-type(1)');
      const header2 = document.querySelector('.header:nth-of-type(2)');
      const section1 = document.querySelector('.section:nth-of-type(1)');
      const section2 = document.querySelector('.section:nth-of-type(2)');
      const rect = section1.getBoundingClientRect();
      if (rect.top < 0) {
        header1.style.position = 'absolute';
        header1.style.top = '0';
        header2.style.position = 'sticky';
        header2.style.top = '0';
      } else {
        header1.style.position = 'sticky';
        header1.style.top = '0';
        header2.style.position = 'absolute';
        header2.style.top = `${rect.top}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    return () => {
      hideHUC(map, currentHUC);
      if(curMarker) {
        curMarker.remove();
      }
    };
  }, [curMarker]);

  const handleCardClick = (coords) => {
    if(curMarker) {
      curMarker.remove();
    }
    const lng = coords?.longitude;
    const lat = coords?.latitude;
    const point = [lng, lat];
    const newMarker = createMarkerWithoutIcon({ lngLat: { lat, lng } }, map);
    setCurMarker(newMarker);
    flyToPoint(map, point);
  };

  const handleWatershedView = () => {
    console.log({ streamGageList });
    if(isWatershedVisible) {
      hideHUC(map, streamGageList?.trueHuc === '' ? currentHUC : streamGageList?.trueHuc);
      setIsWatershedVisible(!isWatershedVisible);
    } else {
      showHUC(map, streamGageList?.trueHuc === '' ? currentHUC : streamGageList?.trueHuc);
      setIsWatershedVisible(!isWatershedVisible);
    }
  };

  const handleGraphView = (id) => {
    const gage = {
      properties: {
        STAID: id,
      },
    };
    dispatch(setCurrentGage(gage));
    dispatch(setIsGageChartShown(true));
  };

  const getWebsite = (id) => {
    return `https://waterdata.usgs.gov/monitoring-location/${id}/`;
  };

  return (
    <div className={styles.mainContainer}>
      {!isPro && <div className={styles.boxUpgrade}> <UpgradeBox title={proTitle} description={proDescription} /> </div>}

      <div className={`${styles.container} ${!isPro ? styles.blurBackground : ''}`}>
        <div className={`${styles.header} header`}>On this river</div>
        <div className={`${styles.section} section`}>
          {streamGageList?.inCurrentRiver?.length <= 0
        && (
          <div className={styles.emptyMessage}>
            No USGS located in this River
          </div>
        )}
          {streamGageList?.inCurrentRiver?.length > 0 && streamGageList?.inCurrentRiver?.filter((data) => data !== undefined && !Number.isNaN(data?.coordinate?.latitude) && !Number.isNaN(data?.coordinate?.longitude)).map((cGage) => {
            return (
              <div className={styles.gageContainer}
                role="button"
                tabIndex={0}
                onClick={() => handleCardClick(cGage?.coordinate)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleCardClick(cGage?.coordinate);
                  }
                }}
                key={cGage?.id}
              >
                <div className={styles.titleContainer}>
                  <Image src={gage} height="45" width="45" />
                  <div>
                    <div className={styles.title}>{cGage?.title}</div>
                    <div className={styles.subtitle}>Station ID: {cGage?.id}</div>
                  </div>
                </div>
                <div className={styles.cardContainer}>
                  <div>
                    <div className={styles.cardTitle}>Flow</div>
                    <div className={styles.cardValueContainer}>
                      <div className={styles.cardIcon}><WavesOutlined /></div>
                      <div className={styles.cardValue}><span>{cGage?.streamFlowAvailable === 1 ? cGage?.IVFlow.value : 'N/A'}</span> cfs</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.cardTitle}>Height</div>
                    <div className={styles.cardValueContainer}>
                      <div className={styles.cardIcon}><HeightOutlined /></div>
                      <div className={styles.cardValue}><span>{cGage?.streamHeightAvailable === 1 ? cGage?.IVHeight.value : 'N/A'}</span> ft</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.cardTitle}>Temp</div>
                    <div className={styles.cardValueContainer}>
                      <div className={styles.cardIcon}><Image src={thermostat} height="20" width="20" style={{ marginTop: '3px' }} /></div>
                      <div className={styles.cardValue}><span>{cGage?.streamTempAvailable === 1 ? cGage?.IVTemp.value : 'N/A'}</span>&deg;</div>
                    </div>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <Button label="Website" className={styles.button} iconElement={LanguageOutlined} onClick={(e) => { e.stopPropagation(); window.open(getWebsite(cGage?.id), '_blank'); }} />
                  <Button label="Graph" className={styles.button} iconElement={TrendingUpOutlined} onClick={(e) => { e.stopPropagation(); handleGraphView(cGage?.id); }} />
                </div>
              </div>
            );
          })}
        </div>
        <div className={`${styles.header} header`}>
          Within the watershed
          <button className={styles.watershedButton} onClick={handleWatershedView} type="button">{isWatershedVisible ? 'Hide' : 'Show'} watershed</button>
        </div>
        <div className={`${styles.section} section`}>
          {streamGageList?.inCurrentWatershed?.length <= 0
        && (
          <div className={styles.emptyMessage}>
            No USGS located in this Watershed
          </div>
        )}
          {streamGageList?.inCurrentWatershed?.filter((data) => data !== undefined && !Number.isNaN(data?.coordinate?.latitude) && !Number.isNaN(data?.coordinate?.longitude)).map((cGage) => {
            return (
              <div className={styles.gageContainer}
                role="button"
                tabIndex={0}
                onClick={() => handleCardClick(cGage?.coordinate)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleCardClick(cGage?.coordinate);
                  }
                }}
                key={cGage?.id}
              >
                <div className={styles.titleContainer}>
                  <Image src={gage} height="45" width="45" />
                  <div>
                    <div className={styles.title}>{cGage?.title}</div>
                    <div className={styles.subtitle}>Station ID: {cGage?.id}</div>
                  </div>
                </div>
                <div className={styles.cardContainer}>
                  <div>
                    <div className={styles.cardTitle}>Flow</div>
                    <div className={styles.cardValueContainer}>
                      <div className={styles.cardIcon}><WavesOutlined /></div>
                      <div className={styles.cardValue}><span>{cGage?.streamFlowAvailable === 1 ? cGage?.IVFlow.value : 'N/A'}</span> cfs</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.cardTitle}>Height</div>
                    <div className={styles.cardValueContainer}>
                      <div className={styles.cardIcon}><HeightOutlined /></div>
                      <div className={styles.cardValue}><span>{cGage?.streamHeightAvailable === 1 ? cGage?.IVHeight.value : 'N/A'}</span> ft</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.cardTitle}>Temp</div>
                    <div className={styles.cardValueContainer}>
                      <div className={styles.cardIcon}><Image src={thermostat} height="20" width="20" style={{ marginTop: '3px' }} /></div>
                      <div className={styles.cardValue}><span>{cGage?.streamTempAvailable === 1 ? cGage?.IVTemp.value : 'N/A'}</span>&deg;</div>
                    </div>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <Button label="Website" className={styles.button} iconElement={LanguageOutlined} onClick={(e) => { e.stopPropagation(); window.open(getWebsite(cGage?.id), '_blank'); }} />
                  <Button label="Graph" className={styles.button} iconElement={TrendingUpOutlined} onClick={(e) => { e.stopPropagation(); handleGraphView(cGage?.id); }} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default StreamFlowsTab;
