/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import useForm from 'utils/useForm';
import {
  Checkbox, FormHelperText, InputLabel, Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login, ActionTypes } from 'actions';
import errorCodes from 'lib/error-codes';
// import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import {
  getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider,
} from 'firebase/auth';
import { checkUserProfileExists, updateUserProfileInfo } from 'services/firebase';
import useSubmitLogic from './useSubmitLogic';
import FormContainer from './FormContainer';
import styles from './styles.module.scss';

const useStyles = makeStyles({
  root: {
    display: 'inline',
    fontSize: '14px',
    marginLeft: '-3px',
    color: '#333333',
  },
});

const helperTextStyles = makeStyles({
  root: {
    fontSize: '14px',
    textAlign: 'center',
  },
});

const buttonStyles = makeStyles({
  root: {
    backgroundColor: '#EBEBEB',
    borderRadius: '24px',
    color: 'black',
    fontWeight: 700,
    marginTop: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    fontSize: '1rem',
  },
});

const LoginForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const helperTextClasses = helperTextStyles();
  const {
    values, onChange, errors, validate,
  } = useForm({ email: { required: true, validate: 'email' }, password: { required: true } });
  const { trySubmit, loading, authError } = useSubmitLogic(login, validate, values);
  const dispatch = useDispatch();
  const buttonClasses = buttonStyles();
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');

  useEffect(() => {
    if (authError === errorCodes['auth/wrong-password']) {
      setPasswordErrorMessage('');
    } else if (errors.password) {
      setPasswordErrorMessage('Password is required.');
    } else {
      setPasswordErrorMessage('');
    }
  }, [errors, authError]);

  const tryGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const userProfileExists = await checkUserProfileExists();
        if (!userProfileExists) {
          updateUserProfileInfo();
        }
        console.log(result);
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Google sign in error: ${errorCode} ${errorMessage}`);
      });
  };

  const tryOnxIdentityLogin = () => {
    const provider = new OAuthProvider('oidc.onx-identity');
    provider.addScope('openid');
    provider.addScope('email');
    provider.addScope('profile');
    // provider.addScope('offline_access');

    provider.setCustomParameters({
      login_hint: values.email,
    });
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const userProfileExists = await checkUserProfileExists();

        if (!userProfileExists) {
          updateUserProfileInfo();
        }
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Onx Identity sign in error: ${errorCode} ${errorMessage}`);
      });
  };

  return (
    <FormContainer title="Log In to TroutRoutes">
      <FormContainer.BottomSection text="Don't have an account?" buttonTitle="Sign Up" onClick={() => { history.push('/signup'); }} />

      <form className={styles.form}>
        <FormContainer.FormElement
          title="Email Address"
          name="email"
          onChange={onChange}
          value={values.email}
          error={errors.email || (authError && authError !== errorCodes['auth/wrong-password']) ? (authError || 'Enter a valid email address.') : ''}
        />
        <FormContainer.FormElement
          title="Password"
          name="password"
          onChange={onChange}
          value={values.password}
          isPassword
          error={passwordErrorMessage}
        />

        <div className={styles.checkbox}>
          <Checkbox disableRipple name="remember" id="remember" color="primary" size="small" />
          <InputLabel htmlFor="remember" classes={classes}>Remember Me</InputLabel>
        </div>

        {authError === errorCodes['auth/wrong-password'] && <FormHelperText classes={helperTextClasses} error>Login failed. Incorrect email or password.</FormHelperText>}

        <FormContainer.SubmitButton title="Log In" onClick={trySubmit} loading={loading} />

        <FormContainer.OnxIdentityButton onClick={tryOnxIdentityLogin} loading={loading} />
        <FormContainer.GoogleIdentityButton onClick={tryGoogleLogin} loading={loading} />
      </form>

      <div className={styles.wrapper}>
        <div className={styles.options}>
          <Link className={styles.bottomLink} underline="none" onClick={() => { history.push('/forgot'); }}>Forgot Password?</Link>
          <div className={styles['divider-vertical']} />
          <Link className={styles.bottomLink} underline="none" onClick={() => { window.open('https://troutroutes.com/terms-of-use/', '_blank'); }}>Terms of Use</Link>
          <div className={styles['divider-vertical']} />
          <Link className={styles.bottomLink} underline="none" onClick={() => { window.open('https://troutroutes.com/terms-of-use/', '_blank'); }}>Privacy Policy</Link>
        </div>

      </div>
    </FormContainer>
  );
};

export default LoginForm;
