/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import errorCodes from 'lib/error-codes';
import useIsFlyshopMode from 'utils/useIsFlyshopMode';
import { Button, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'components/Image';
import { googleIcon } from 'assets';
import {
  getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider,
} from 'firebase/auth';
import { checkUserProfileExists, updateUserProfileInfo } from 'services/firebase';
import FormContainer from './FormContainer';
import styles from './styles.module.scss';

const buttonStyles = makeStyles({
  root: {
    backgroundColor: '#EBEBEB',
    borderRadius: '24px',
    color: 'black',
    fontWeight: 700,
    marginTop: '5px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#BDBDBD',
      boxShadow: 'none',
    },

  },
  label: {
    fontSize: '16px',
    paddingTop: 4,
    paddingBottom: 4,
  },
});

const helperTextStyles = makeStyles({
  root: {
    fontSize: '14px',
    textAlign: 'center',
  },
});

const SignUpForm = ({
  onChange, values, errors, authError, loginError, signUpClick, loading,
}) => {
  const isFlyshopMode = useIsFlyshopMode();
  const helperTextClasses = helperTextStyles();
  const buttonClasses = buttonStyles();

  const GoogleSignUp = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const userProfileExists = await checkUserProfileExists();
        if (!userProfileExists) {
          updateUserProfileInfo();
        }
        console.log(result);
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Google sign in error: ${errorCode} ${errorMessage}`);
      });
  };

  return (
    <form className={styles.form}>
      {!isFlyshopMode && (
        <FormContainer.FormElement
          title="First Name"
          name="firstName"
          onChange={onChange}
          value={values.firstName}
          error={errors.firstName ? 'Required.' : ''}
        />
      )}
      {!isFlyshopMode && (
        <FormContainer.FormElement
          title="Last Name"
          name="lastName"
          onChange={onChange}
          value={values.lastName}
          error={errors.lastName ? 'Required.' : ''}
        />
      )}
      <FormContainer.FormElement
        title="Email Address"
        name="email"
        onChange={onChange}
        value={values.email}
        error={errors.email || (authError && authError !== errorCodes['auth/weak-password']) ? (authError || 'Invalid email.') : ''}
      />
      <FormContainer.FormElement
        title="Password"
        name="password"
        onChange={onChange}
        value={values.password}
        isPassword
        error={errors.password || authError === errorCodes['auth/weak-password']
          ? (authError || (values.password.length > 0 ? 'Password must be longer than 6 characters.' : 'Password is required.'))
          : ''}
      />
      {loginError && <FormHelperText classes={helperTextClasses} error>{loginError}</FormHelperText>}
      <FormContainer.SubmitButton title="Create Account" onClick={signUpClick} loading={loading} />
      {/* <Button
        disableRipple
        classes={buttonClasses}
        variant="contained"
        color="primary"
        fullWidth
        size="medium"
        onClick={GoogleSignUp}
      >
        <Image src={googleIcon} alt="Download from Google Play" width={25} height={25} maxWidth={25} objectFit="cover" />
          &nbsp; Sign Up with Google
      </Button> */}

      <div className={styles.bottomText}>
        <span>By signing up you agree to our&nbsp;</span>
        <a className={styles.bottomLink} href="https://troutroutes.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        <span>&nbsp; and&nbsp; </span>
        <a className={styles.bottomLink} href="https://troutroutes.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a>
        <span>.</span>
      </div>
    </form>
  );
};

export default SignUpForm;
