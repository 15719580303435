
/* eslint-disable */
import React from 'react';
import {
  markerTypeBoat, markerTypeCamp, markerTypeDam, markerTypeFishing, markerTypeFlyShop,
  markerTypeGas, markerTypeHabitat, markerTypeLake, markerTypeRestaurant, markerTypeParking, markerTypeRestroom,
  markerTypeFavorite, markerTypeTrailhead, markerTypeNotes, dashedLine, dottedLine, solidLine, thinWidth, thickWidth, mediumWidth,
}from 'assets';

import { FavIcon, FishingIcon, HabitIcon, LakeIcon, NoteIcon, ParkingIcon, BoatIcon, CampIcon, TrailheadIcon, FlyShopIcon, BarIcon, RestroomIcon, DamIcon, GasIcon } from './Icons';


export const version = '4.10.1';
export const profileDatabaseURL = 'https://troutroutes-users.firebaseio.com/';
export const eCommerceDatabaseURL = 'https://troutroutesreferrals.firebaseio.com/';
export const gageListDatabaseURL = 'https://streamcard-gages.firebaseio.com/';
export const geoSearchDatabaseURL = 'https://troutroutes-geosearch-4-1.firebaseio.com/';
export const elevationDatabaseURL = 'https://hires-geography-051424.firebaseio.com/';
export const wordpressDatabaseURL = 'https://tr-wordpress-testing.firebaseio.com/';
export const directionsURL = 'https://www.google.com/maps/search/?api=1&query=';
export const directionCoordinateURL = 'https://www.google.com/maps/dir/?api=1&destination=';
export const subscriptionProductsURL = 'https://troutinsights.com/product/troutroutes-pro-annual-subscription/';
export const editableUserFields = ['user_firstname', 'user_lastname'];
export const layersEnum = {
  basic: 'basic',
  guide: 'guide',
  access: 'access',
  regulations: 'regulations',
  roads: 'roads',
  topography: 'topography',
  satellite: 'satellite',
};
export const accountTypesEnum = {
  pro: 'pro',
  basic: 'basic',
  trial: 'trial',
};
export const mapboxDemSource = 'mapbox://mapbox.mapbox-terrain-dem-v1';
export const classGoldStreamColor = '#e8b923';
export const classOneStreamColor = '#33cc33';
export const classTwoStreamColor = '#22C6FC';
export const classThreeStreamColor = '#336fb8';

export const supportedStates = [23, 7, 2, 5, 37, 17, 27, 47, 8, 45, 14, 34, 42, 31, 48, 33, 19, 50, 16, 35, 49, 46, 6, 10, 1, 22, 36, 13, 41, 21, 44, 9, 12];

export const mapMarkerTypes = [
  {
    shortName: 'Favorite', title: 'Favorite Spot', mapRef: 'favorite', displayPosition: 0, img: markerTypeFavorite, upload: true, index: 0, icon: <FavIcon />,
  },
  {
    shortName: 'Boat Ramp', title: 'Boat Ramp', mapRef: 'boat', displayPosition: 13, img: markerTypeBoat, index: 1, icon: <BoatIcon />,
  },
  {
    shortName: 'Camp Site', title: 'Camp Site', mapRef: 'camp', displayPosition: 9, img: markerTypeCamp, index: 2, icon: <CampIcon />,
  },
  {
    shortName: 'Parking', title: 'Parking', mapRef: 'park', displayPosition: 4, img: markerTypeParking, index: 3, icon: <ParkingIcon />,
  },
  {
    shortName: 'Fishing Access', title: 'Fishing Access', mapRef: 'fishingaccess', displayPosition: 2, img: markerTypeFishing, upload: true, index: 4, icon: <FishingIcon />,
  },
  {
    shortName: 'Trailhead', title: 'Trailhead', mapRef: 'trail', displayPosition: 5, img: markerTypeTrailhead, upload: true, index: 5, icon: <TrailheadIcon />,
  },
  {
    shortName: 'Fly Shop', title: 'Fly Shop', mapRef: 'flyshop', displayPosition: 10, img: markerTypeFlyShop, upload: true, index: 6, icon: <FlyShopIcon />,
  },
  {
    shortName: 'Restaurant', title: 'Restaurant / Bar', mapRef: 'restaurant', displayPosition: 7, img: markerTypeRestaurant, upload: true, index: 7, icon: <BarIcon />,
  },
  {
    shortName: 'Restroom', title: 'Restroom', mapRef: 'restroom', displayPosition: 12, img: markerTypeRestroom, upload: true, index: 8, icon: <RestroomIcon />,
  },
  {
    shortName: 'Dam', title: 'Dam', mapRef: 'dam', displayPosition: 11, img: markerTypeDam, upload: true, index: 9, icon: <DamIcon />,
  },
  {
    shortName: 'Gas', title: 'Gas', mapRef: 'gas', displayPosition: 8, img: markerTypeGas, upload: true, index: 10, icon: <GasIcon />,
  },
  {
    shortName: 'Lake', title: 'Lake', mapRef: 'lake', displayPosition: 6, img: markerTypeLake, upload: true, index: 11, icon: <LakeIcon />,
  },
  {
    shortName: 'Habitat Improvement', title: 'Habitat Improvement', mapRef: 'habitatimprovement', displayPosition: 3, img: markerTypeHabitat, upload: true, index: 12, icon: <HabitIcon />,
  },
  {
    shortName: 'Note', title: 'Note', mapRef: 'note', displayPosition: 1, img: markerTypeNotes, upload: true, index: 13, icon: <NoteIcon />,
  },
];
export const lineColors = [
  { color: '#1553d3', index: 0 }, //blue
  { color: '#ff0000', index: 1 }, //red
  { color: '#f6e100', index: 2 }, //yellow
  { color: '#800080', index: 3 }, //purple
  { color: '#82d400', index: 4 }, //green
  { color: '#ff8000', index: 5 }, //orange
  { color: '#000000', index: 6 }, //black
  { color: '#808080', index: 7 }, //gray
  { color: '#ffffff', index: 8 }, //white
  { color: '#8b4511', index: 9 }, //brown
];
export const lineTypes = [
  { dashArray: [2, 0], index: 0, icon: solidLine },
  { dashArray: [3, 1.5], index: 1, icon: dashedLine },
  { dashArray: [0, 1.5], index: 2, icon: dottedLine },
];
export const lineWidths = [
  { width: 2, index: 0, icon: thinWidth },
  { width: 4, index: 1, icon: mediumWidth },
  { width: 6, index: 2, icon: thickWidth },
];
export const lineStyles = [
  {
    id: 'gl-draw-line-solid',
    type: 'line',
    filter: ['all',
      ['==', '$type', 'LineString'],
      ['==', 'user_dashType', 0],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_color'],
      'line-width': ['get', 'user_width'],
      'line-dasharray': lineTypes[0].dashArray,
    },
  },
  {
    id: 'gl-draw-vertex',
    type: 'circle',
    filter: ['all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#666',
    },
  },
  {
    id: 'gl-draw-line-dashed',
    type: 'line',
    filter: ['all',
      ['==', '$type', 'LineString'],
      ['==', 'user_dashType', 1],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_color'],
      'line-width': ['get', 'user_width'],
      'line-dasharray': lineTypes[1].dashArray,
    },
  },
  {
    id: 'gl-draw-line-dotted',
    type: 'line',
    filter: ['all',
      ['==', '$type', 'LineString'],
      ['==', 'user_dashType', 2],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_color'],
      'line-width': ['get', 'user_width'],
      'line-dasharray': lineTypes[2].dashArray,
    },
  },
];
export const areaStyles = [
  {
    id: 'gl-draw-area',
    type: 'fill',
    filter: ['all',
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['has', 'user_color'],
    ],
    paint: {
      'fill-color': ['get', 'user_color'],
      'fill-outline-color': 'rgba(255,255,255,0)',
      'fill-opacity': ['get', 'user_opacity'],
    },
  },
  {
    id: 'gl-draw-area',
    type: 'fill',
    filter: ['all',
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': lineColors[0].color,
      'fill-outline-color': 'rgba(255,255,255,0)',
      'fill-opacity': 0.5,
    },
  },
];
export const gageChartTimes = {
  day: 'day',
  month: 'month',
  year: 'year',
};
export const gageChartTypes = {
  flow: 'flow',
  height: 'height',
};
export const snapRegions = [
  {
    title: 'Alabama', bounds: { lat: 32.98526239959924, lng: -87.09696442252222 }, zoom: 6.2, gid: 15, abbrev: 'AL',
  },
  {
    title: 'Arizona', bounds: { lat: 34.113198155103376, lng: -111.41474427828172 }, zoom: 6.2, gid: 3, abbrev: 'AZ',
  },
  {
    title: 'Arkansas', bounds: { lat: 34.62546334720139, lng: -92.51592792717894 }, zoom: 6.2, gid: 4, abbrev: 'AR',
  },
  {
    title: 'California', bounds: { lat: 37.91302551617824, lng: -120.66685704937751 }, zoom: 5.14, gid: 1, abbrev: 'CA',
  },
  {
    title: 'Colorado', bounds: { lat: 39.23933384391101, lng: -106.29760919704562 }, zoom: 7.4, gid: 45, abbrev: 'CO',
  },
  {
    title: 'Connecticut', bounds: { lat: 41.487242468814244, lng: -72.62734697243827 }, zoom: 7.18, gid: 22, abbrev: 'CT',
  },
  {
    title: 'Delaware', bounds: { lat: 39.162510418264986, lng: -75.2909327519717 }, zoom: 7.18, gid: 26, abbrev: 'DE',
  },
  {
    title: 'Georgia', bounds: { lat: 34.49440726368077, lng: -84.18383794961943 }, zoom: 8.1, gid: 27, abbrev: 'GA',
  },
  {
    title: 'Idaho', bounds: { lat: 45.11425837364941, lng: -114.41782222356052 }, zoom: 6, gid: 32, abbrev: 'ID',
  },
  {
    title: 'Illinois', bounds: { lat: 40.4561017987355, lng: -89.31525515481053 }, zoom: 6.06, gid: 31, abbrev: 'IL',
  },
  {
    title: 'Indiana', bounds: { lat: 40.32440294030691, lng: -86.18281181657964 }, zoom: 6.55, gid: 34, abbrev: 'IN',
  },
  {
    title: 'Iowa', bounds: { lat: 43.04144712851374, lng: -91.64446313973143 }, zoom: 8.66, gid: 33, abbrev: 'IA',
  },
  {
    title: 'Kansas', bounds: { lat: 38.94997937901267, lng: -98.9122900865312 }, zoom: 7.66, gid: 49, abbrev: 'KS',
  },
  {
    title: 'Kentucky', bounds: { lat: 37.88141393270041, lng: -85.67100773681133 }, zoom: 6.7, gid: 2, abbrev: 'KY',
  },
  {
    title: 'Maine', bounds: { lat: 45.552761799529584, lng: -69.27353564358593 }, zoom: 6.2, gid: 36, abbrev: 'ME',
  },
  {
    title: 'Maryland', bounds: { lat: 39.26955635839651, lng: -76.65469568185195 }, zoom: 7.2, gid: 24, abbrev: 'MD',
  },
  {
    title: 'Massachusetts', bounds: { lat: 42.204087676145846, lng: -72.0181436957349 }, zoom: 6.57, gid: 13, abbrev: 'MA',
  },
  {
    title: 'Michigan', bounds: { lat: 43.9263267980577, lng: -84.76973196624003 }, zoom: 6.14, gid: 14, abbrev: 'MI',
  },
  {
    title: 'Minnesota', bounds: { lat: 46.054769915277625, lng: -93.82178736891456 }, zoom: 6.1, gid: 48, abbrev: 'MN',
  },
  {
    title: 'Missouri', bounds: { lat: 37.98186280414728, lng: -91.85852161757118 }, zoom: 6.3, gid: 19, abbrev: 'MO',
  },
  {
    title: 'Montana', bounds: { lat: 47.167290546419565, lng: -112.29683503179606 }, zoom: 5.5, gid: 6, abbrev: 'MT',
  },
  {
    title: 'Nebraska', bounds: { lat: 42.053481577273246, lng: -103.19593749291215 }, zoom: 7.15, gid: 35, abbrev: 'NE',
  },
  {
    title: 'Nevada', bounds: { lat: 40.13065413242345, lng: -117.16703166664206 }, zoom: 6.3, gid: 11, abbrev: 'NE',
  },
  {
    title: 'New Hampshire', bounds: { lat: 43.75915632333264, lng: -71.48564183359258 }, zoom: 6.77, gid: 41, abbrev: 'NH',
  },
  {
    title: 'New Jersey', bounds: { lat: 40.34064590703068, lng: -74.75534083053375 }, zoom: 6.77, gid: 40, abbrev: 'NJ',
  },
  {
    title: 'New Mexico', bounds: { lat: 34.82918936354253, lng: -106.2036905415996 }, zoom: 6.7, gid: 39, abbrev: 'NM',
  },
  {
    title: 'New York', bounds: { lat: 43.26457946754931, lng: -75.74483083842033 }, zoom: 6.8, gid: 38, abbrev: 'NY',
  },
  {
    title: 'North Carolina', bounds: { lat: 35.845, lng: -81.454 }, zoom: 7.0, gid: 37, abbrev: 'NC',
  },
  {
    title: 'North Dakota', bounds: { lat: 47.319444444, lng: -101.3825 }, zoom: 8, gid: 50, abbrev: 'ND',
  },
  {
    title: 'Ohio', bounds: { lat: 40.33940122224791, lng: -82.75843806828485 }, zoom: 7.0, gid: 47, abbrev: 'OH',
  },
  {
    title: 'Oklahoma', bounds: { lat: 34.99259794541143, lng: -97.48058416565739 }, zoom: 6.2, gid: 20, abbrev: 'OK',
  },
  {
    title: 'Oregon', bounds: { lat: 44.743520891039864, lng: -121.84842449426651 }, zoom: 6.08, gid: 21, abbrev: 'OR',
  },
  {
    title: 'Pennsylvania', bounds: { lat: 40.693888387513404, lng: -78.20680039041068 }, zoom: 7.0, gid: 8, abbrev: 'PA',
  },
  {
    title: 'Rhode Island', bounds: { lat: 41.74202211678697, lng: -71.59564951182213 }, zoom: 8.6, gid: 44, abbrev: 'RI',
  },
  {
    title: 'South Carolina', bounds: { lat: 34.9635800810304, lng: -82.85733536675528 }, zoom: 9.1, gid: 17, abbrev: 'SC',
  },
  {
    title: 'South Dakota', bounds: { lat: 43.99027381045589, lng: -103.69372335766975 }, zoom: 7.5, gid: 16, abbrev: 'SD',
  },
  {
    title: 'Tennessee', bounds: { lat: 35.922, lng: -84.941 }, zoom: 7.0, gid: 5, abbrev: 'TN',
  },
  {
    title: 'Texas', bounds: { lat: 31.546187929933616, lng: -99.42217211595161 }, zoom: 6.2, gid: 43, abbrev: 'TX',
  },
  {
    title: 'Utah', bounds: { lat: 40.42564501163511, lng: -112.18014393591741 }, zoom: 6.9, gid: 10, abbrev: 'UT',
  },
  {
    title: 'Vermont', bounds: { lat: 43.85138397826836, lng: -72.6193515619443 }, zoom: 6.88, gid: 9, abbrev: 'VT',
  },
  {
    title: 'Virginia', bounds: { lat: 37.66112962591215, lng: -80.11725076338224 }, zoom: 7.3, gid: 23, abbrev: 'VA',
  },
  {
    title: 'Washington', bounds: { lat: 45.87352047141761, lng: -121.83935767706431 }, zoom: 5.67, gid: 12, abbrev: 'WA',
  },
  {
    title: 'West Virginia', bounds: { lat: 38.71084464895088, lng: -80.4466920794821 }, zoom: 7.3, gid: 7, abbrev: 'WV',
  },
  {
    title: 'Wisconsin', bounds: { lat: 44.727340072725475, lng: -90.43694351032062 }, zoom: 6.3, gid: 42, abbrev: 'WI',
  },
  {
    title: 'Wyoming', bounds: { lat: 42.94361901032361, lng: -108.46566923176123 }, zoom: 5.5, gid: 46, abbrev: 'WY',
  },
];
