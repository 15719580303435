/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  favoriteIcon, toolPin, toolLine, toolArea, riverIcon, noteIcon, linePopupIcon, shapePopupIcon,
} from 'assets';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateStreamWithFavorite, setEditingMarkerId, setEditingLineId, setEditingAreaId, setElevation, setTRPOIFeature,
} from 'actions';
import { mapMarkerTypes } from 'lib/constants';
import SearchItem from 'components/SearchBar/SearchItem';
import FeatureButton from 'components/FeatureButton';
import { getElevationPoint } from 'services/elevation';
import moment from 'moment';
import Pane from '../Pane';
import ContentSection from './ContentSection';

const ContentPane = ({ toggle }) => {
  const dispatch = useDispatch();
  const {
    favorites, markers, lines, areas, areaIds, lineIds,
  } = useSelector((state) => state.user);
  const map = useSelector((state) => state.map.map);
  const draw = useSelector((state) => state.map.draw);
  const { marker } = useSelector((state) => state.map);

  const getElevationValue = async (stream) => {
    const coordinates = {
      lat: (stream?.ymin + stream?.ymax) / 2,
      lng: (stream?.xmin + stream?.xmax) / 2,
    };
    const elevation = await getElevationPoint(coordinates);
    dispatch(setElevation(elevation));
  };

  const onClickStream = (stream) => {
    dispatch(updateStreamWithFavorite(stream));
    toggle();
    getElevationValue(stream);
  };

  const onClickMarker = (coordinates, id) => {
    map.flyTo({ center: coordinates, zoom: 14 }, { programmatic: true });
    if (marker && marker.tapMarker) {
      marker.tapMarker.remove();
    }
    dispatch(setTRPOIFeature(null));
    map.fire('closeAllPopups');
    dispatch(setEditingMarkerId(id));
    toggle();
  };

  const onClickLine = (coordinates, id) => {
    map.flyTo({ center: coordinates[Math.floor(coordinates.length / 2)], zoom: 12 }, { programmatic: true });
    Object.entries(lineIds).forEach(([lineId, firebaseId]) => {
      if (firebaseId === id) {
        draw.changeMode('simple_select', { featureIds: [lineId] });
        dispatch(setEditingLineId(lineId));
      }
    });
    toggle();
  };

  const onClickArea = (coordinates, id) => {
    map.flyTo({ center: coordinates[Math.floor(coordinates.length / 2)][0], zoom: 12 }, { programmatic: true });
    Object.entries(areaIds).forEach(([areaId, firebaseId]) => {
      if (firebaseId === id) {
        draw.changeMode('simple_select', { featureIds: [areaId] });
        dispatch(setEditingAreaId(areaId));
      }
    });
    toggle();
  };

  return (
    <Pane title="My Content">
      <ContentSection icon={favoriteIcon} title="Favorite Streams" count={favorites.length}>
        {favorites.map((stream) => {
          const { primaryLabel, secondaryLabel, stream_gid } = stream;
          return (
            <SearchItem
              background="#F8F6F2"
              icon={riverIcon}
              title={primaryLabel}
              location={secondaryLabel}
              key={stream_gid}
              onClick={() => { onClickStream(stream); }}
            />
          );
        })}
      </ContentSection>

      <ContentSection icon={toolPin} title="Markers" count={Object.keys(markers).length}>
        {Object.entries(markers).map(([id, marker]) => {
          const {
            title, pointType, date, note,
          } = marker.properties;
          const icons = [];
          if (note) icons.push(noteIcon);
          return (
            <FeatureButton
              background="#F8F6F2"
              mainIcon={mapMarkerTypes[(pointType && pointType < mapMarkerTypes.length) ? pointType : 0].img}
              title={title}
              subtitle={date}
              key={marker?.geometry?.coordinates[0]}
              onClick={() => { onClickMarker(marker?.geometry?.coordinates, id); }}
              icons={icons}
            />
          );
        })}
      </ContentSection>

      <ContentSection icon={toolLine} title="Lines" count={Object.keys(lines).length}>
        {Object.entries(lines).map(([id, line]) => {
          const {
            title, date, note,
          } = line.properties;
          const icons = [];
          if (note) icons.push(noteIcon);
          return (
            <FeatureButton
              background="#F8F6F2"
              mainIcon={linePopupIcon}
              title={title}
              subtitle={moment(date).format('LL')}
              key={line?.geometry?.coordinates[0][0]}
              onClick={() => { onClickLine(line?.geometry?.coordinates, id); }}
              icons={icons}
            />
          );
        })}
      </ContentSection>

      <ContentSection icon={toolArea} title="Areas" count={Object.keys(areas).length}>
        {Object.entries(areas).map(([id, area]) => {
          const {
            title, date, note,
          } = area.properties;
          const icons = [];
          if (note) icons.push(noteIcon);
          return (
            <FeatureButton
              background="#F8F6F2"
              mainIcon={shapePopupIcon}
              title={title}
              subtitle={moment(date).format('LL')}
              key={area?.geometry?.coordinates[0][0]}
              onClick={() => { onClickArea(area?.geometry?.coordinates, id); }}
              icons={icons}
            />
          );
        })}
      </ContentSection>
    </Pane>
  );
};

ContentPane.propTypes = {
  toggle: PropTypes.func,
};

export default ContentPane;
