/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ModalTemplate = ({
  children, open, close, className, delayFade,
}) => {
  const [delayedFade, setDelayedFade] = useState(open);
  const userClasses = useStyles();

  useEffect(() => {
    if (delayFade && !open) setTimeout(() => { setDelayedFade(false); }, 500);
    else setDelayedFade(open);
  }, [open, delayFade]);

  return (
    <Modal
      className={userClasses.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={delayedFade}>
        <div className={`${styles.container} ${className}`}>
          <button className={styles.close} type="button" onClick={close}><CloseIcon width={50} height={50} style={{ fill: '#8B8B8B' }} /></button>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

ModalTemplate.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default ModalTemplate;
