import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ZoomButton = ({
  icon: Icon, onClick, name, style = {}, active = false,
}) => {
  return (
    <button className={styles.zoom} type="button" onClick={onClick} name={name} aria-label="Zoom Button" style={{ ...style, background: active ? 'rgb(225,225,225)' : 'white' }}>
      <Icon width={25} height={25} name={name} />
    </button>
  );
};

ZoomButton.propTypes = {
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  name: PropTypes.string,
  style: PropTypes.object,
  active: PropTypes.bool,
};

export default ZoomButton;
