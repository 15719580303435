import React, { useCallback, useState, useEffect } from 'react';
import ContentDialog from 'components/ContentDialog';
import {
  lineColors, lineWidths, lineTypes, directionsURL,
} from 'lib/constants';
import { createLine, setDelayedPanelShown, deleteLine } from 'actions';
import { linePopupIcon } from 'assets';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import ExploreIcon from '@material-ui/icons/Explore';
import ShareIcon from '@material-ui/icons/Share';
import * as firebaseService from 'services/firebase';
import { PhotoDisplay, AddPhotoButton } from 'components/Photos';
import useUpload from 'utils/useUpload';
import useIsPro from 'utils/useIsPro';

const EditLineDialog = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const draw = useSelector((state) => state.map.draw);
  const { lineId } = useSelector((state) => state.map.line);
  const { lines, lineIds } = useSelector((state) => state.user);

  const [lineName, setLineName] = useState('');
  const [currentColor, setCurrentColor] = useState(lineColors[0]);
  const [currentWidth, setCurrentWidth] = useState(lineWidths[1]);
  const [currentType, setCurrentType] = useState(lineTypes[0]);
  const [notes, setNotes] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [images, setImages] = useState([]);

  const {
    images: tempImages, handleChange, setImages: setTempImages, loading, handleUpload,
  } = useUpload();

  const reset = useCallback(() => {
    if (lineId && lines[lineIds[lineId]]) {
      const lineInfo = lines[lineIds[lineId]];
      const {
        title, note, lineColorIndex, lineWidthIndex, lineTypeIndex,
      } = lineInfo.properties;
      setLineName(title);
      setNotes(note);
      setCurrentColor(lineColorIndex ? lineColors[lineColorIndex] : lineColors[0]);
      setCurrentWidth(lineWidthIndex ? lineWidths[lineWidthIndex] : lineWidths[1]);
      setCurrentType(lineTypeIndex ? lineTypes[lineTypeIndex] : lineTypes[0]);
      setTempImages([]);
    }
  }, [lineId, lineIds, lines]);

  const onCancel = () => {
    reset();
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onSubmit = () => {
    const line = draw.get(lineId);
    dispatch(createLine({
      colorIndex: currentColor.index, title: lineName, note: notes, geometry: line.geometry, widthIndex: currentWidth.index, typeIndex: currentType.index,
    }, lineIds[lineId]));

    if (tempImages) {
      handleUpload(lineIds[lineId], 'line');
    } else {
      setIsEditing(false);
    }
  };

  const onDelete = () => {
    dispatch(deleteLine(lineIds[lineId]));
  };

  const onDeleteImage = (imageObject) => {
    const { id } = imageObject;

    if (id) {
      firebaseService.deleteImage(lineIds[lineId], id, 'line');
      setImages((prev) => prev.filter((v) => v.id !== id));
    } else {
      setTempImages((prev) => prev.filter((v) => v !== imageObject));
    }
  };

  useEffect(() => {
    if (!lineId) {
      dispatch(setDelayedPanelShown(false));
      setImages([]);
    } else {
      setTimeout(() => { dispatch(setDelayedPanelShown(true)); }, 600);
      const { properties } = lines[lineIds[lineId]];
      firebaseService.getImageURLsForAnnotation(lineIds[lineId], properties?.imageURLs || [], 'line').then((results) => setImages(results));
    }
  }, [lineId, lines]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (!loading) {
      setIsEditing(false);
    }
  }, [loading]);

  useEffect(() => {
    if (lineId && draw.get(lineId)) {
      draw.setFeatureProperty(lineId, 'color', currentColor.color);
      draw.setFeatureProperty(lineId, 'width', currentWidth.width);
      draw.setFeatureProperty(lineId, 'dashType', currentType.index);

      const line = draw.get(lineId);
      draw.delete(lineId);
      draw.add(line);
      draw.changeMode('simple_select', { featureIds: [lineId] }); // Ensures selection after the first click.
    }
  }, [currentColor, currentWidth, currentType, lineId, draw]);

  if (!lineId) return null;

  const properties = lines[lineIds[lineId]]?.properties;
  const geometry = lines[lineIds[lineId]]?.geometry;
  const hasImages = !!properties?.imageURLs;

  return (
    <ContentDialog>
      <ContentDialog.Header icon={linePopupIcon} title={lineName} subtitle="Line" isEditing={isEditing} onChange={(e) => { setLineName(e.target.value); }} />
      <ContentDialog.Buttons
        buttons={isEditing
          ? [
            { label: 'Cancel', onClick: onCancel },
            { label: 'Save', onClick: onSubmit },
          ]
          : [
            { label: 'Edit', iconElement: EditIcon, onClick: onEdit },
            { label: 'Directions', iconElement: ExploreIcon, onClick: () => { window.open(`${directionsURL}${encodeURI(`${geometry?.coordinates[1]}, ${geometry?.coordinates[0]}`)}`); } },
            // { label: 'Share', iconElement: ShareIcon },
          ]}
        style={{ justifyContent: isEditing ? 'flex-start' : 'space-between' }}
        disabled={loading}
      />
      <ContentDialog.Heading>Notes</ContentDialog.Heading>

      {isEditing
        ? <ContentDialog.MultiLineField onChange={(e) => { setNotes(e.target.value); }} value={notes} name="notes" label="Notes" />
        : <ContentDialog.Text>{notes || 'No notes yet. Tap \'edit\' to create one!'}</ContentDialog.Text>}

      {(hasImages || isEditing) && isPro && (
        <>
          <ContentDialog.Heading>
            <div>Photos</div>
            {isEditing && <AddPhotoButton id="line-upload" onChange={handleChange} />}
          </ContentDialog.Heading>
          <PhotoDisplay images={[...images, ...tempImages]} onDelete={onDeleteImage} />
        </>
      )}

      <ContentDialog.SubmitSection buttons={[{ label: 'Delete', error: true, onClick: onDelete }]} />
    </ContentDialog>
  );
};

export default EditLineDialog;
