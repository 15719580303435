const authTypes = {
  AUTHENTICATE: 'AUTHENTICATE',
  REGISTER_USER: 'REGISTER_USER',
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  APP_LOAD: 'APP_LOAD',
  AUTH_ERROR: 'AUTH_ERROR',
  CLEAR_AUTH_ERROR: 'CLEAR_AUTH_ERROR',
  LOGOUT: 'LOGOUT',
  REGISTERING: 'REGISTERING',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  UPDATE_ACCOUNT_INFO: 'UPDATE_ACCOUNT_INFO',
  SHOW_START_NOTIFICATION: 'SHOW_START_NOTIFICATION',
  SHOW_END_NOTIFICATION: 'SHOW_END_NOTIFICATION',
  UPDATE_MOBILE_MODAL_SHOWN: 'UPDATE_MOBILE_MODAL_SHOWN',
  UPDATE_FLYSHOP_MODE: 'UPDATE_FLYSHOP_MODE',
  UPDATE_FLYSHOP_PASSWORD_MODAL_SHOWN: 'UPDATE_FLYSHOP_PASSWORD_MODAL_SHOWN',
};

const mapTypes = {
  UPDATE_COORDINATES: 'UPDATE_COORDINATES',
  UPDATE_BASE_LAYER: 'UPDATE_BASE_LAYER',
  UPDATE_MAP_STYLE: 'UPDATE_MAP_STYLE',
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_CLASSES: 'UPDATE_CLASSES',
  UPDATE_FEATURES: 'UPDATE_FEATURES',
  UPDATE_CURRENT_STREAM: 'UPDATE_CURRENT_STREAM',
  UPDATE_SEARCH_RESULTS: 'UPDATE_SEARCH_RESULTS',
  UPDATE_ACTIVE_TOOLS: 'UPDATE_ACTIVE_TOOLS',
  SET_TEMP_MARKER: 'SET_TEMP_MARKER',
  SET_TAP_MARKER: 'SET_TAP_MARKER',
  SET_LOCATION_MARKER: 'SET_LOCATION_MARKER',
  SET_MAP: 'SET_MAP',
  SET_EDITING_MARKER_ID: 'SET_EDITING_MARKER_ID',
  SET_MARKER_TYPE: 'SET_MARKER_TYPE',
  UPDATE_LINES: 'UPDATE_LINES',
  UPDATE_LINE_ID: 'UPDATE_LINE_ID',
  RESET_LINE_IDS: 'RESET_LINE_IDS',
  SET_TEMP_LINE: 'SET_TEMP_LINE',
  SET_EDITING_LINE_ID: 'SET_EDITING_LINE_ID',
  SET_DRAW: 'SET_DRAW',
  SET_TEMP_AREA: 'SET_TEMP_AREA',
  SET_EDITING_AREA_ID: 'SET_EDITING_AREA_ID',
  RESET_AREA_IDS: 'RESET_AREA_IDS',
  UPDATE_AREA_ID: 'UPDATE_AREA_ID',
  UPDATE_AREAS: 'UPDATE_AREAS',
  SET_CURRENT_HUC: 'SET_CURRENT_HUC',
  SET_STREAM_ELEVATION: 'SET_STREAM_ELEVATION',
  SET_STREAM_GAGE_DATA: 'SET_STREAM_GAGE_DATA',
  SET_SLOPE_CHART_VISIBLITY: 'SET_SLOPE_CHART_VISIBLITY',
  SET_ELEVATION_CHART_VISIBLITY: 'SET_ELEVATION_CHART_VISIBLITY',
  SET_RIVER_MILES_VISIBLITY: 'SET_RIVER_MILES_VISIBLITY',
  SET_RIVER_MILES_PRIMARY_LABEL: 'SET_RIVER_MILES_PRIMARY_LABEL',
  SET_RIVER_MILES_STEP: 'SET_RIVER_MILES_STEP',
  SET_RIVER_MILES_MARKERS: 'SET_RIVER_MILES_MARKERS',
  SET_RIVER_MILES_PARENT_LINE: 'SET_RIVER_MILES_PARENT_LINE',
  SET_RIVER_MILES_SLICED_LINE_COORDINATES: 'SET_RIVER_MILES_SLICED_LINE_COORDINATES',
  SET_RIVER_MILES_DISTANCE: 'SET_RIVER_MILES_DISTANCE',
  SET_ELEVATION_MARKER: 'SET_ELEVATION_MARKER',
  SET_CURRENT_GAGE: 'SET_CURRENT_GAGE',
  SET_GAGE_DATA: 'SET_GAGE_DATA',
  SET_IS_GAGE_CHART_SHOWN: 'SET_IS_GAGE_CHART_SHOWN',
  CLEAR_GAGE_DATA: 'CLEAR_GAGE_DATA',
  SET_CURRENT_TIME: 'SET_CURRENT_TIME',
  SET_CURRENT_CHART_TYPE: 'SET_CURRENT_CHART_TYPE',
  SET_ACTIVE_SIDEBAR: 'SET_ACTIVE_SIDEBAR',
  SET_DELAYED_PANEL: 'SET_DELAYED_PANEL',
  SET_STREAM_USER_DATA: 'SET_STREAM_USER_DATA',
  SET_STREAM_GEOGRAPHY: 'SET_STREAM_GEOGRAPHY',
  UPDATE_STREAM_GEOGRAPHY: 'UPDATE_STREAM_GEOGRAPHY',
  SET_UPGRADE_MODAL: 'SET_UPGRADE_MODAL',
  SET_SNAP_MODAL: 'SET_SNAP_MODAL',
  SET_GOOGLE_PLACES_UPLOAD: 'SET_GOOGLE_PLACES_UPLOAD',
  SET_CONFIRM_UPLOAD_MODAL: 'SET_CONFIRM_UPLOAD_MODAL',
  SET_STREAM_REGULATIONS: 'SET_STREAM_REGULATIONS',
  SET_STREAM_METADATA: 'SET_STREAM_METADATA',
  SET_STREAM_FLY_SHOPS: 'SET_STREAM_FLY_SHOPS',
  SET_SUPPORTED_STATES: 'SET_SUPPORTED_STATES',
  UPDATE_ELEVATION: 'UPDATE_ELEVATION',
  SET_TR_POI_FEATURE: 'SET_TR_POI_FEATURE',
};

const userTypes = {
  UPDATE_FAVORITES: 'UPDATE_FAVORITES',
  UPDATE_MARKERS: 'UPDATE_MARKERS',
  ADD_MARKER_OBJECT: 'ADD_MARKER_OBJECT',
  CLEAR_MARKER_OBJECTS: 'CLEAR_MARKER_OBJECTS',
  UPDATE_PASSWORD_MODAL_SHOWN: 'UPDATE_PASSWORD_MODAL_SHOWN',
  UPDATE_REGION: 'UPDATE_REGION',
};

const paymentTypes = {
  SET_PAYMENT_ERROR: 'SET_PAYMENT_ERROR',
  SET_PAYMENT_LOADING: 'SET_PAYMENT_LOADING',
  SET_PAYMENT_RESULT: 'SET_PAYMENT_RESULT',
  SET_IS_PAYMENT_MODAL_SHOWN: 'SET_IS_PAYMENT_MODAL_SHOWN',
  SET_IS_CANCEL_MODAL_SHOWN: 'SET_IS_CANCEL_MODAL_SHOWN',
  SET_IS_UPDATE_MODAL_SHOWN: 'SET_IS_UPDATE_MODAL_SHOWN',
  SET_COUPON: 'SET_COUPON',
  SET_COUPON_MESSAGE: 'SET_COUPON_MESSAGE',
  SET_PERCENT_OFF: 'SET_PERCENT_OFF',
  SET_PRICE: 'SET_PRICE',
  UPDATE_DEFAULT_PROMOCODE: 'UPDATE_DEFAULT_PROMOCODE',
  UPDATE_DEFAULT_MEMBERCODE: 'UPDATE_DEFAULT_MEMBERCODE',
};

export const ActionTypes = {
  ...authTypes,
  ...mapTypes,
  ...userTypes,
  ...paymentTypes,
};

export * from './auth';
export * from './map';
export * from './user';
export * from './payment';
