/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import useForm from 'utils/useForm';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'utils/amplitude';
import {
  ActionTypes, cancelSubscription, createAccount, login, loginFlyshopMode, updateFlyshopPasswordModalShown, setIsSnapModalShown, setActiveSidebar,
} from 'actions';
import { useDispatch } from 'react-redux';
import * as firebaseService from 'services/firebase';
import Disclaimer from 'components/PaymentScreen/Disclaimer';
import { analytics, verifyAccountStatus } from 'services/firebase';
import useIsFlyshopMode from 'utils/useIsFlyshopMode';
import ChangePasswordModal from 'components/ChangePasswordModal';
import { getStatusByEmail, subscribeToRevenueCat } from 'services/stripe';
import { accountTypesEnum } from 'lib/constants';
import MembershipCard from 'components/PaymentScreen/MembershipCard';
import PlanPath from 'components/PaymentScreen/PlanPath';
import ReactGA from 'react-ga4';
import useSubmitLogic from './useSubmitLogic';
import FormContainer, { linkStyles } from './FormContainer';
import SignUpForm from './SignUpForm';
import InfoSection from '../PaymentScreen/InfoSection';
import InputSection from '../PaymentScreen/InputSection';
import styles from './styles.module.scss';

const CreateAccountForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  const classes = linkStyles();
  const isFlyshopMode = useIsFlyshopMode();
  const [loginError, setLoginError] = useState(null);

  const {
    values, onChange, errors, validate, setValues,
  } = useForm({
    email: { required: true, validate: 'email' },
    // confirmEmail: { required: true, validate: 'confirmEmail' },
    password: { required: true, validate: 'password' },
    firstName: { required: true, defaultValue: isFlyshopMode ? 'Flyshow' : '' },
    lastName: { required: true, defaultValue: isFlyshopMode ? 'User' : '' },
  });
  const { trySubmit, loading, authError } = useSubmitLogic(createAccount, validate, values);
  const dispatch = useDispatch();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const defaultMemberCode = params.get('membercode');
  const defaultMemberCodeExists = ((defaultMemberCode != null) && (defaultMemberCode != ''));
  const [displayMembershipCard, setDisplayMembershipCard] = useState(defaultMemberCodeExists);

  const checkProUser = async () => {
    const status = await getStatusByEmail(values.email);
    return status === accountTypesEnum.pro;
  };

  const submitBasic = async () => {
    sendAmplitudeEvent('web_sign_up_button_clicked-continue-as-basic');
    dispatch(setIsSnapModalShown(true));
    dispatch(setActiveSidebar(''));
    tryLogin();
  };

  useEffect(() => {
    if (isFlyshopMode) {
      setValues({ ...values, firstName: 'Flyshow', lastName: 'User' });
    }
  }, [isFlyshopMode]);

  const registerUser = async () => {
    dispatch({ type: ActionTypes.REGISTERING, payload: true });
    try {
      const user = await firebaseService.register(
        values.email,
        values.password,
        { firstName: values.firstName, lastName: values.lastName },
      );
      console.log('values User', user.email, user.uid);
      dispatch({ type: ActionTypes.REGISTER_USER, payload: user });
      setCurrentStep(1);
    } catch (error) {
      dispatch({ type: ActionTypes.REGISTERING, payload: false });
      setLoginError(error.message);
    }
  };

  const handleSignUpClick = () => {
    if (validate()) {
      if (isFlyshopMode) {
        checkProUser()
          .then((isPro) => {
            if (isPro) {
              tryLogin();
            } else {
              registerUser();
            }
          });
      } else {
        registerUser();
      }
    }
  };

  const incrementStep = (stepNum) => {
    if (validate(false)) {
      setCurrentStep(stepNum);
    }
  };

  const tryLogin = () => {
    dispatch(login(values.email, values.password, true));
  };

  const onTrialCreate = (data) => {
    trySubmit(data);
    sendAmplitudeEvent('web_sign_up_button_clicked');
    ReactGA.event('trial_start');
    analytics.logEvent('trial_start', data);
    analytics.logEvent('sign_up_button_clicked');
  };

  const onPasswordConfirm = async () => {
    dispatch(updateFlyshopPasswordModalShown(false));
    await dispatch(loginFlyshopMode(values.email, values.password));
    await subscribeToRevenueCat(values.subscriptionId, undefined, `${values.firstName} ${values.lastName}`);
    await verifyAccountStatus(dispatch);
  };

  useEffect(() => {
    if (authError && !isFlyshopMode) {
      dispatch(cancelSubscription(true));
    }
  }, [authError, isFlyshopMode]);

  useEffect(() => {
    analytics.logEvent('sign_up_page_loaded');
  }, []);

  const onMembershipCardChange = (value) => {
    setDisplayMembershipCard(value);
  };

  sendAmplitudeEvent('web_pageVisitCreateAccountForm');

  return (
    <FormContainer title="Sign Up for an Account">
      <FormContainer.SwitchToSignIn text="Already have an account?" buttonTitle="Log In" onClick={() => { history.push(`/login${history.location.search}`); }} />
      <div className={styles['status-container']}>
        <button type="button" className={`${styles.status} ${styles.active}`} onClick={() => { setCurrentStep(0); }}>Create Account</button>
        <button type="button" className={`${styles.status} ${currentStep >= 1 ? styles.active : styles.inactive}`} onClick={() => { if (!isFlyshopMode) incrementStep(1); }}>Plan & Billing</button>
      </div>

      {currentStep === 0 && (
        <SignUpForm
          onChange={onChange}
          values={values}
          errors={errors}
          authError={authError}
          loginError={loginError}
          loading={loading}
          signUpClick={handleSignUpClick}
        />
      )}

      {currentStep === 1 && (
        <div className={styles['payment-section']}>
          <InfoSection error={authError} displayMembershipCard={displayMembershipCard} />
          {!displayMembershipCard && <PlanPath />}
          {!displayMembershipCard && (
            <InputSection
              email={values.email}
              name={`${values.firstName} ${values.lastName}`}
              onTrialCreate={onTrialCreate}
              tryLogin={tryLogin}
              // checkEmail
              displayMembershipCard={displayMembershipCard}
              onMembershipCardChange={onMembershipCardChange}
            />
          )}
          {displayMembershipCard && (
            <MembershipCard createAccount={onTrialCreate}
              email={values.email}
              displayMembershipCard={displayMembershipCard}
              onMembershipCardChange={onMembershipCardChange}
            />
          )}
          <Disclaimer />
          <div className={styles['container-centered']}>
            <FormContainer.ContinueAsBasic text="" buttonTitle="Skip" onClick={submitBasic} />
          </div>
        </div>
      )}

      <ChangePasswordModal onClick={onPasswordConfirm} />
    </FormContainer>
  );
};

export default CreateAccountForm;
