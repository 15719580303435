import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import {
  setisFileUploadSectionShown,
  createMarker,
  createLine,
  createArea,
} from 'actions';
import { mapMarkerTypes } from 'lib/constants';
import ConfirmUploadModal from './ConfirmUploadModal';
import styles from './styles.module.scss';

function UploadZone({ selectedMarkerType }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isConfirmationShown, setIsConfirmationShown] = useState(false);
  const { isFileUploadSectionShown } = useSelector((state) => state.map);
  const dispatch = useDispatch();

  const isDisabled = !uploadedFiles.length > 0;

  const clear = () => {
    setUploadedFiles([]);
    setFiles([]);
    setFeatures([]);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.gpx,.kml,.geojson,application/json,application/vnd.geo+json',
    maxSize: 4 * 1024 * 1024, // 4 MB in bytes
    onDrop: (acceptedFiles) => {
      const fileNames = uploadedFiles.map(({ file }) => file.name);
      const fileIds = uploadedFiles.map(({ id }) => id);
      const updatedFiles = acceptedFiles.filter(
        (file) => !fileNames.includes(file.name) && !fileIds.includes(file.lastModified),
      ).map((file) => ({
        id: file.lastModified,
        file,
      }));
      setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    },
  });

  const handleFileUpload = () => {
    const selectedFiles = uploadedFiles.map(({ file }) => file);
    setFiles(selectedFiles);
  };

  useEffect(() => {
    const uploadFile = async () => {
      for (const file of files) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const fileContent = e.target.result;
          try {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const { DOMParser } = require('@xmldom/xmldom');
            const fileParsedFromDom = new DOMParser().parseFromString(fileContent, 'text/xml');
            const togeojson = require('@mapbox/togeojson');
            let convertedFile;
            if (fileExtension === 'gpx') {
              convertedFile = togeojson.gpx(fileParsedFromDom);
            } else if (fileExtension === 'kml') {
              convertedFile = togeojson.kml(fileParsedFromDom);
            } else if (fileExtension === 'geojson') {
              convertedFile = JSON.parse(fileContent);
            }
            const { features } = convertedFile;
            setFeatures((prevFeatures) => [...prevFeatures, ...features]);
          } catch (e) {
            console.error(e);
          }
        };
        reader.readAsText(file);
      }
      setIsConfirmationShown(true);
    };

    if (files.length > 0) {
      uploadFile().catch((error) => console.error(error));
    }
  }, [files]);

  const handleFeatures = () => {
    features.forEach((feature) => {
      const { type } = feature.geometry;
      const handler = featureHandlers[type];

      if (handler) {
        handler(feature);
      } else {
        console.log('The file you uploaded contains an unsupported feature type:', type);
      }
    });
  };

  const getNotes = (feature) => {
    const { properties } = feature;
    let notes;

    if (properties.notes) {
      notes = properties.notes || '';
    } else if (properties.description) {
      notes = properties.description || '';
    } else if (properties.desc) {
      if (properties.desc.includes('notes=')) {
        notes = properties.desc.split('notes=')[1]?.split('\n')[0] || '';
      } else {
        notes = properties.desc || '';
      }
    } else {
      notes = '';
    }
    console.log('Notes', notes);
    return notes;
  };

  const importMarkers = (feature) => {
    if (feature.geometry.coordinates) {
      const notes = getNotes(feature);
      dispatch(createMarker({
        markerType: mapMarkerTypes[selectedMarkerType],
        lngLat: {
          lng: feature.geometry.coordinates[0],
          lat: feature.geometry.coordinates[1],
        },
        name: feature.properties.name || feature.properties.Name,
        notes: `[Imported Marker] ${notes}`,
      }));
    }
  };

  const importLines = (feature) => {
    if (feature.geometry.coordinates) {
      const notes = getNotes(feature);
      dispatch(createLine({
        colorIndex: 1,
        title: feature.properties.name || feature.properties.Name,
        note: `[Imported Line] ${notes}`,
        geometry: feature.geometry,
        widthIndex: 1,
        typeIndex: 1,
      }));
    }
  };

  const importAreas = (feature) => {
    if (feature.geometry.coordinates) {
      const notes = getNotes(feature);
      const geoObj = {
        coordinates: feature.geometry.coordinates[0],
        type: feature.geometry.type,
      };

      dispatch(createArea({
        colorIndex: 1,
        title: feature.properties.name || feature.properties.Name,
        note: `[Imported Area] ${notes}`,
        geometry: geoObj,
        widthIndex: 0,
        typeIndex: 0,
        outlineOpacity: 1,
        fillOpacity: 0.5,
      }));
    }
  };

  const featureHandlers = {
    Point: importMarkers,
    LineString: importLines,
    Polygon: importAreas,
  };

  if (!isFileUploadSectionShown) return null;

  return (
    <>
      <div className={styles.uploadContent}>
        <div className={styles.uploadBox} {...getRootProps()}>
          <input type="file" style={{ display: 'none' }} {...getInputProps({ maxSize: 4 * 1024 * 1024 })} accept=".gpx,.kml,.geojson,application/json,application/vnd.geo+json" />
          <p>Drag and drop GeoJSON, GPX, or KML file(s) here</p>
          <h4>OR</h4>
          <label className={styles.upload}>
            <button type="button" className={styles.upload}>Upload From Computer</button>
          </label>
          <p className={styles.fileSize}>Max file size 4 MB</p>
        </div>
        {uploadedFiles.length > 0 && (
          <>
            <ul className={styles.fileList}>
              {uploadedFiles.map(({ id, file }) => (
                <li key={id}>{file.name}&nbsp;&nbsp;<span className={styles.fileSize}>{(file.size / 1024).toFixed(2)} KB</span></li>
              ))}
            </ul>
            {uploadedFiles.some(({ file }) => file.name.endsWith('.gpx')) && (
              <p className={styles.warn}>
                * Note: GPX format does not support polygons/areas, instead they will be imported as lines.
              </p>
            )}
          </>
        )}
        <div className={styles.clearUploaded}>
          <button type="button" disabled={isDisabled} className={styles.upload} onClick={handleFileUpload}>Import</button>
          <button type="button" disabled={isDisabled} className={styles.clear} onClick={clear}>Clear Selected Files</button>
        </div>
      </div>

      <ConfirmUploadModal
        isShown={isConfirmationShown}
        close={() => {
          setIsConfirmationShown(false);
          clear();
          dispatch(setisFileUploadSectionShown(false));
        }}
        upload={handleFeatures}
        numMarkers={features?.length || 0}
      />
    </>
  );
}

export default UploadZone;
