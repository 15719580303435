/*eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import ContentDialog from 'components/ContentDialog';
import CreateDialog from 'components/CreateDialog';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMarkerType, setDelayedPanelShown, deleteMarker, createMarker, setEditingMarkerId,
} from 'actions';
import { mapMarkerTypes, directionsURL, lineColors } from 'lib/constants';
import EditIcon from '@material-ui/icons/Edit';
import ExploreIcon from '@material-ui/icons/Explore';
import ShareIcon from '@material-ui/icons/Share';
import * as firebaseService from 'services/firebase';
import { PhotoDisplay, AddPhotoButton } from 'components/Photos';
import useUpload from 'utils/useUpload';
import useIsPro from 'utils/useIsPro';
import toast from 'react-hot-toast';
import ColorButton from 'components/CreateLine/ColorButton';
import Image from 'components/Image';
import * as mapboxService from 'services/mapbox';
import { getElevationPoint } from '../../services/elevation';
import Select from 'react-select';
import { store } from '../../index';
import {
  distanceIcon, elevationIcon, closeIcon, directionsIcon2, shareIcon2, createIcon2, editIcon2, deleteIcon2,
} from 'assets';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';

const EditMarkerDialog = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const { tempMarker: marker, currentMarkerType: markerTypeIndex, markerId } = useSelector((state) => state.map.marker);
  const userMarkers = useSelector((state) => state.user.markers);
  const [markerName, setMarkerName] = useState('');
  const [notes, setNotes] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [images, setImages] = useState([]);
  const [currentMarkerType, setCurrentMarkerType] = useState(markerTypeIndex);
  const [currentColor, setCurrentColor] = useState(lineColors[0]);
  const [distance, setDistance] = useState();
  const [elevation, setElevation] = useState(0);
  const [address, setAddress] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const { trPOIFeature } = useSelector((state) => state.map.trPOIFeature);
  const map = useSelector((state) => state.map.map);

  const {
    images: tempImages, handleChange, setImages: setTempImages, loading, handleUpload,
  } = useUpload();

  useEffect(() => {
    setCurrentMarkerType(markerTypeIndex);
  }, [markerTypeIndex]);

  const reset = useCallback(async () => {
    if (markerId) {
      const { properties, geometry } = userMarkers[markerId];
      const { title, note, pointType } = properties;
      setMarkerName(title);
      setNotes(note || '');
      setCurrentMarkerType(markerTypeIndex);
      const markerType = mapMarkerTypes.find((type) => type.index == pointType) || { index: 0 };
      dispatch(setMarkerType(markerType.index));
      setTempImages([]);
      setIsDelete(false);
      mapboxService.createMarkerHighlight(geometry.coordinates, map);
      const elevationInfo = await getElevationPoint({ lat: geometry.coordinates[1], lng: geometry.coordinates[0] });
      setElevation(elevationInfo);
      setDistance(mapboxService.distanceFormat(geometry.coordinates, false));

      if(trPOIFeature) {  
        if (trPOIFeature.properties.SecondaryLabel) {
          if (trPOIFeature.properties.SecondaryLabel.includes(',')) {
            setAddress(trPOIFeature.properties.SecondaryLabel.replace(',', ', '));
          } else {
            setAddress(trPOIFeature.properties.SecondaryLabel);
          }
        } else {
          setAddress('');
        }
  
        if(trPOIFeature.properties.elevation_ft) {
          setElevation(trPOIFeature.properties.elevation_ft);
        } else {
          const elevationInfo = await getElevationPoint({ lat: trPOIFeature.geometry.coordinates[1], lng: trPOIFeature.geometry.coordinates[0] });
          setElevation(elevationInfo);
        }
      }
    }
  }, [markerId, userMarkers]);
  
  const onDelete = () => {
    setIsDelete(true);
  };
  
  const onDeleteCancel = () => {
    setIsDelete(false);
  };

  const onDeleteConfirm = () => {
    dispatch(deleteMarker(markerId));
    const { tapMarker } = store.getState().map.marker;
    if (tapMarker) {
      tapMarker.remove();
    }
  }

  const onDeleteImage = (imageObject) => {
    const { id } = imageObject;

    if (id) {
      firebaseService.deleteImage(markerId, id, 'marker');
      setImages((prev) => prev.filter((v) => v.id !== id));
    } else {
      setTempImages((prev) => prev.filter((v) => v !== imageObject));
    }
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  const onSubmit = () => {
    try {
      const markerType = mapMarkerTypes[currentMarkerType];
      const { geometry, } = userMarkers[markerId];
      const lngLat = { lng: geometry.coordinates[0], lat: geometry.coordinates[1] }

      dispatch(createMarker({
        lngLat, name: markerName, markerType, notes: notes, pointColorIndex: currentColor.index.toString(),
      }, markerId));

      if (tempImages) {
        handleUpload(markerId, 'marker');
      } else {
        setIsEditing(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (markerId) {
      reset();
    } else {
      setIsEditing(false);
    }
  }, [markerId, reset]);

  useEffect(() => {
    if (markerId) {
      const { properties } = userMarkers[markerId];

      if (properties?.pointColorIndex) {
        const colorIndex = Number(properties?.pointColorIndex);
        setCurrentColor(lineColors[colorIndex]);
      } else {
        setCurrentColor(lineColors[0]);
      }

      setTimeout(() => { dispatch(setDelayedPanelShown(true)); }, 600);
      firebaseService.getImageURLsForAnnotation(markerId, properties?.imageURLs || [], 'marker').then((results) => setImages(results));
    } else {
      dispatch(setDelayedPanelShown(false));
      setImages([]);
    }
  }, [markerId, userMarkers]);

  const changeColor = (currentColor) => {
    setCurrentColor(currentColor)

    const markerType = mapMarkerTypes[currentMarkerType];

    const { geometry, } = userMarkers[markerId];

    const lngLat = { lng: geometry.coordinates[0], lat: geometry.coordinates[1] }
    const icon = React.cloneElement(markerType.icon, { colorIndex: currentColor?.index.toString() });

    mapboxService.createIconMarker(lngLat, map, icon);
  }

  useEffect(() => {
    if (!loading) {
      setIsEditing(false);
    }
  }, [loading]);

  if (!markerId) return null;

  const { title, img, icon: markerIcon } = mapMarkerTypes[currentMarkerType];
  const { properties, geometry } = userMarkers[markerId];
  const icon = React.cloneElement(markerIcon, { colorIndex: currentColor.index.toString() });
  
  const hasImages = !!properties?.imageURLs;

  const onShare = async () => {
    const { geometry } = userMarkers[markerId];
    if (!geometry.coordinates || geometry.coordinates.length != 2) return;

    const lng = geometry.coordinates[0];
    const lat = geometry.coordinates[1];
    try {
      const url = await firebaseService.generateDynamicLink(lat, lng);
      navigator.clipboard.writeText(url);
      toast.remove();
      toast('Copied link to clipboard');
    } catch (e) {
      console.log(e);
    }
  };

  const onClose = async () => {
    dispatch(setEditingMarkerId(''));
    reset();
    const { tapMarker } = store.getState().map.marker;
    if (tapMarker) {
      tapMarker.remove();
    }
  };
  
  // color dropdown
  var colors = [];
  lineColors.forEach((color) => {
    colors.push({ value: color.index, label: color.color });
  });
  const customOptionColor = (props) => {
    const { innerRef, innerProps, data } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ padding: '4px 8px' }}>
        <div className={styles.selectColor} style={{ background: data.label }}></div>
      </div>
    );
  };
  const customSingleValueColor = ({ data }) => (
    <div>
      <div className={styles.selectColor} style={{ background: data.label }}></div>
    </div>
  );

  // type dropdown
  var types = [];
  mapMarkerTypes.forEach((type) => {
    types.push({ value: type.index, label: type.shortName, img: type.img });
  });
  const customOptionType = (props) => {
    const { innerRef, innerProps, data } = props;
    return (
      <div ref={innerRef} {...innerProps} className={styles.selectType} style={{ padding: '4px 8px' }}>
        <img src={data.img} />
        <div>{data.label}</div>
      </div>
    );
  };
  const customSingleValueType = ({ data }) => (
    <div className={styles.selectType}>
      <img src={data.img} />
      <div>{data.label}</div>
    </div>
  );

  const onChangeType = (ev) => {
    const markerType = mapMarkerTypes[ev.value];
    const { geometry, } = userMarkers[markerId];
    const lngLat = { lng: geometry.coordinates[0], lat: geometry.coordinates[1] };
    setCurrentMarkerType(ev.value);

    dispatch(createMarker({
      lngLat, name: markerName, markerType, notes: notes, pointColorIndex: currentColor.index.toString(),
    }, markerId));
  };

  const onChangeColor = (ev) => {
    const curClr = lineColors.find((m) => m.index === ev.value);
    setCurrentColor(curClr);
    const markerType = mapMarkerTypes[currentMarkerType];
    const { geometry, } = userMarkers[markerId];
    const lngLat = { lng: geometry.coordinates[0], lat: geometry.coordinates[1] }
    const icon = React.cloneElement(markerType.icon, { colorIndex: currentColor?.index.toString() });

    mapboxService.createIconMarker(lngLat, map, icon);
  }

  const markerCurType = mapMarkerTypes[currentMarkerType];

  return (
    <>
    {isEditing && (
    <CreateDialog close={onCancel} title="Edit Marker">      
      <div className={styles.flex} style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Select
          className={styles.selector}
          isSearchable={false}
          defaultValue={{ value: currentColor.index, label: currentColor.color }}
          options={colors}
          components={{ Option: customOptionColor, SingleValue: customSingleValueColor }}
          onChange={onChangeColor}
        />
        <Select
          className={styles.selector}
          isSearchable={false}
          defaultValue={{ value: markerCurType.index, label: markerCurType.shortName, img: markerCurType.img}}
          options={types}
          components={{ Option: customOptionType, SingleValue: customSingleValueType }}
          onChange={onChangeType}
        />
      </div>

      <CreateDialog.TextInput
        name="name"
        onChange={(e) => { setMarkerName(e.target.value); }}
        value={markerName}
      />

      <div className={styles.address}>
        {address}
      </div>
      <div className={styles.distances}>
        <div className={styles.distanceInner}>
          <Image alt="Distance" src={distanceIcon} width="16" />
          <p>{distance}</p>
        </div>
        <div className={styles.distanceInner}>
          <Image alt="Elevation" src={elevationIcon} width="16" />
          <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft. elevation</p>
        </div>
      </div>

      <CreateDialog.Section title="Notes">
        <CreateDialog.MultiLineField
          name="notes"
          label="Notes"
          value={notes}
          onChange={(e) => { setNotes(e.target.value); }}
        />
      </CreateDialog.Section>

      <div className={styles.divider} style={{ marginBottom: '0px' }} />
      {isPro && (
        <CreateDialog.Section title="Photos" headerIcon={<AddPhotoButton id="create-marker" onChange={handleChange} />}>
          <PhotoDisplay images={[...images, ...tempImages]} onDelete={onDeleteImage} />
          <div className={styles.divider} style={{ marginBottom: '0px' }} />
        </CreateDialog.Section>
      )}

      <CreateDialog.SubmitSection buttons={[{ label: 'Save', onClick: onSubmit }, { label: 'Cancel', onClick: onCancel }]} disabled={loading} />
    </CreateDialog>
    )}
    {!isEditing && (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.close}
          role="button"
          onClick={onClose}
          tabIndex={0}
        >
          <CloseIcon />
          {/* <Image alt="Close" src={closeIcon} width="16" /> */}
        </div>
        <div className={styles.text}>
          <div className={styles.type}>
            {img && icon}
            <h6>{markerCurType.shortName}</h6>
          </div>
          <h4>{markerName}</h4>
          <h5>{address}</h5>
        </div>
      </div>
      
      <div className={styles.scontent}>
        <div className={styles.distances}>
          <div className={styles.distanceInner}>
            <p>{`Created on ${properties?.date}`}</p>
          </div>
        </div>

        <div className={styles.distances}>
          <div className={styles.distanceInner}>
            <Image alt="Distance" src={distanceIcon} width="16" />
            <p>{distance > 10 ? distance.toLocaleString('en-US', { minimumFractionDigits: 0 }) : distance.toLocaleString('en-US', { minimumFractionDigits: 1 })} mi. away</p>
          </div>
          <div className={styles.distanceInner}>
            <Image alt="Elevation" src={elevationIcon} width="16" />
            <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft. elevation</p>
          </div>
        </div>

        <div className={styles.distances}>
          <div className={styles.distanceInner} style={{ display: 'block' }}>
            <div style={{ fontSize: '16px', fontWeight: '700' }}>Notes</div>
            <p>{notes || 'No notes yet. Tap \'edit\' to create one!'}</p>
          </div>
        </div>
        
        {isPro && (
          <div className={styles.distances}>
            <div className={styles.distanceInner} style={{ display: 'block', width: '100%' }}>
              <div style={{ fontSize: '16px', fontWeight: '700' }}>Photos</div>
              <PhotoDisplay images={[...images, ...tempImages]} onDelete={onDeleteImage} />
            </div>
          </div>
        )}
      </div>
      
      <div className={styles.buttons}>
        <button className={styles.button} type="button" onClick={() => onEdit()}>
          <Image src={editIcon2} alt="Edit Marker" />
          <div>Edit</div>
        </button>
        <button className={styles.button} type="button" onClick={() => {window.open(`${directionsURL}${encodeURI(`${geometry.coordinates[1]}, ${geometry.coordinates[0]}`)}`); }}>
          <Image src={directionsIcon2} alt="Directions" />
          <div>Directions</div>
        </button>
        <button className={styles.button} type="button" onClick={() => onShare()}>
          <Image src={shareIcon2} alt="Share" />
          <div>Share</div>
        </button>
        <button className={styles.button} type="button" onClick={() => onDelete()}>
          <Image src={deleteIcon2} alt="Delete" />
          <div>Delete</div>
        </button>
      </div>

    </div>
    )}
    {isDelete && (
      <div className={styles.deleteConfirm}>
        <div className={styles.deleteContent}>
          <h5>Are you sure you want to delete your saved marker?</h5>
          <p>This cannot be undone.</p>
          <div className={styles.deleteButtons}>
            <div className={styles.deleteButton} onClick={onDeleteCancel}>Cancel</div>
            <div className={styles.deleteButton} onClick={onDeleteConfirm}>Delete</div>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default EditMarkerDialog;
