/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { clearSearch, search } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import SearchPane from './SearchPane';
import styles from './styles.module.scss';

const inputStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiFilledInput-root:after': {
      display: 'none',
    },
    '& .MuiFilledInput-root:before': {
      display: 'none',
    },
    paddingLeft: '3px',
    borderRadius: 20,
  },
  input: {
    fontSize: '16px',
    fontFamily: 'Poppins',
  },
});

const adornmentStyles = makeStyles({
  root: {
    opacity: 0.7,
  },
});

const SearchBar = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [showPane, setShowPane] = useState(false);
  const { searchResults } = useSelector((state) => state.map);
  const inputClasses = inputStyles();
  const adornmentClasses = adornmentStyles();

  useEffect(() => {
    if (searchTerm.length > 1) {
      dispatch(clearSearch());
      dispatch(search(searchTerm));
    } else {
      dispatch(clearSearch());
    }
  }, [searchTerm]);

  return (
    <>
      <div className={styles.container}>
        <TextField
          hiddenLabel
          style={{ width: '100%' }}
          value={searchTerm}
          onChange={(e) => { setSearchTerm(e.target.value); }}
          variant="filled"
          autocomplete="off"
          fullWidth
          classes={inputClasses}
          size="small"
          placeholder="Search map"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" classes={adornmentClasses}>
                <SearchIcon />
              </InputAdornment>
            ),
            classes: inputClasses,
          }}
          inputProps={{
            onFocus: () => { setShowPane(true); },
            onBlur: () => { setShowPane(false); },
            classes: inputClasses.input,
          }}
        />
      </div>
      <SearchPane visible={showPane} results={searchResults} />
    </>
  );
};

export default SearchBar;
